import { Modal } from 'antd';
import React from 'react';
import OrdersByNameTable from '../ordersByName';

const FullScreenModal = ({ visible, onClose, recordId, showOrdersByName, userRecord }: any) => {
  const closeModal = (res: any) => {
    onClose(res);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      maskClosable={false}
      width={'100%'}
      destroyOnClose
      bodyStyle={{ padding: 0, height: '85vh' }}
      title={<h3 className='title-grey'>Orders</h3>}
    >
      <div style={{ height: '100%', overflow: 'auto' }}>
        {showOrdersByName && (
          <OrdersByNameTable recordId={recordId} closeModal={closeModal} userRecord={userRecord} />
        )}
      </div>
    </Modal>
  );
};

export default FullScreenModal;
