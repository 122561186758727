import React, { useEffect, useState } from 'react';
import { Table, message } from 'antd';
import api from '../../config/axios';
import { tax } from '../../config/api-routes';
import { Tax } from '../../schema/user';
import { ColumnsType } from 'antd/es/table';

function Taxes() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Tax[]>([]);

  const taxesTable: ColumnsType<Tax> = [
    {
      title: 'Province',
      dataIndex: 'provinceName',
      align: 'center',
    },
    {
      title: 'Tax Code',
      dataIndex: 'taxCode',
      align: 'center',
    },
    {
      title: 'Rate Type',
      dataIndex: 'rateType',
      align: 'center',
    },
    {
      title: 'Province Tax',
      dataIndex: 'provinceTax',
      align: 'center',
    },
    {
      title: 'Federal Tax',
      dataIndex: 'ferderalTax',
      align: 'center',
    },
  ];
  const getTaxes = () => {
    setLoading(true);

    api
      .get<never, Tax[]>(tax)
      .then((res) => {
        setDataSource(res);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaxes();
  }, []);

  return (
    <div className='mx-2'>
      <h3 className='title-grey'>Taxes</h3>
      <Table
        columns={taxesTable}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        rowKey='taxCode'
      />
    </div>
  );
}

export default Taxes;
