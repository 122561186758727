import React from 'react';
import moment from 'moment';
import { Product } from '../../../schema/order';

type LabelProps = {
  dataSource: Product[];
  shippingWeek: number | undefined;
};

const Label = ({ dataSource, shippingWeek }: LabelProps) => {
  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }
  const getWeekDates = (weekNumber: number): WeekData => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const currentYear = date.getFullYear();

    const firstDayOfYear = new Date(currentYear, 0, 1);

    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

    const startDate = new Date(currentYear, 0, 1 + daysToAdd);
    const endDate = new Date(currentYear, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };
  const shipping: any = shippingWeek && getWeekDates(shippingWeek);
  return (
    <body>
      <div>
      <table cellPadding={0} cellSpacing={0} style={{ border: 'none' }}>
          <tbody>
            {
              dataSource.map((item, index) => (
              <tr key={index}>
                <td style={{ paddingTop: '30px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'left' }}>
                  <span style={{ fontSize: '18px' }}>
                    <b>{item.quantity}</b>
                  </span>
                  <br/>
                  <b style={{ fontSize: '16px' }}>{item.name}</b>
                  <br/>
                  <span style={{ fontSize: '12px' }}>Growing Instructions @unicornblooms.com</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </body>
  );
};

export default Label;
