import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button } from 'antd';
//import { Discounts } from '../../schema/order';

interface EditAddDiscountsProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (item: any) => void;
  onCancelForm: () => void; // New prop to handle form reset
  initialValues?: any;
  popupTitle: string;
  loading: boolean;
}

const EditAddDiscounts: React.FC<EditAddDiscountsProps> = ({
  visible,
  onCancel,
  onSave,
  initialValues,
  popupTitle,
  loading,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef<HTMLFormElement | any>(null);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, visible]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values: any) => {
        initialValues ? onSave({ ...values, id: initialValues.id }) : onSave(values);
      })
      .catch((error: any) => {
        return false;
      });
  };

  const handleKeyDown = (e: any) => {
    // Prevent negative sign '-' from being entered manually
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const validateGreaterThanZero = (_: any, value: number) => {
    if (value > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Value must be greater than 0');
  };

  return (
    <Modal
      open={visible}
      title={initialValues ? `Edit ${popupTitle}` : `Add ${popupTitle}`}
      onCancel={onCancel}
      onOk={handleSave}
      destroyOnClose
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={handleSave}>
          {initialValues ? 'Update' : 'Submit'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        ref={formRef}
        name='discountForm'
        layout='vertical'
        // initialValues={initialValues}
      >
        <Form.Item
          name='discValue'
          label='Discount (%)'
          rules={[
            { required: true, message: 'Please enter discount value' },
            {
              validator: validateGreaterThanZero,
            },
          ]}
        >
          <Input type='number' onKeyDown={handleKeyDown} min={0} />
        </Form.Item>
        <Form.Item
          name='minQty'
          label='Threshold'
          rules={[
            { required: true, message: 'Please enter threshold value' },
            {
              validator: validateGreaterThanZero,
            },
          ]}
        >
          <Input addonBefore={'$'} type='number' onKeyDown={handleKeyDown} min={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAddDiscounts;
