import { Button, DatePicker, DatePickerProps, Form, Select, Table, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import {
  getAllProductLines,
  getDistinctYear,
  getOrderFormsByYear,
  orderSticker,
} from '../../../config/api-routes';
import api from '../../../config/axios';
import { DistinctYear, FormsData, Product, ProductLine } from '../../../schema/order';
import Label from './label';
import ReactDOMServer from 'react-dom/server';
import PackingReport from './packingReport';
import { FileExcelOutlined, FileSearchOutlined, PrinterOutlined } from '@ant-design/icons';
import Papa from 'papaparse';

const PrintLabel = () => {
  const [form] = Form.useForm();
  const [isBulkPL, setISBulkPL] = useState<string>('');
  const [isSticker, setIsSticker] = useState<boolean>();
  const [selectedProductLine, setSelectedProductLine] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedOrderForm, setSelectedOrderForm] = useState<number | null>(null);
  const [selectedShipWeek, setSelectedShipWeek] = useState<number | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | undefined | ''>('');
  const [productTypes, setProductTypes] = useState<ProductLine[]>([]);
  const [distinctYears, setDistinctYears] = useState<DistinctYear[]>([]);
  const [orderFormsData, setOrderFormsData] = useState<FormsData[]>([]);
  const [dataSource, setDataSource] = useState<Product[]>([]);
  const [shippingWeek, setShippingWeek] = useState<number>();
  const [inlineLoading, setInLineLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleProductLineChange = async (id: number) => {
    form.setFieldsValue({ Year: null, OrderForm: null, ShipWeek: null, Type: null });
    setSelectedProductLine(id);
    setInLineLoading(true);
    await api
      .get<never, DistinctYear[]>(`${getDistinctYear}/${id}}`)
      .then((result: DistinctYear[]) => {
        setDistinctYears(result);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
    setInLineLoading(false);
  };

  const handleYearChange = async (year: number) => {
    setSelectedYear(year);
    setInLineLoading(true);

    await api
      .get<never, FormsData[]>(`${getOrderFormsByYear}/${selectedProductLine}?year=${year}`)
      .then((res: FormsData[]) => {
        setOrderFormsData(res);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
    setInLineLoading(false);
  };

  const handleOrderFormChange = (value: number) => {
    setSelectedOrderForm(value);
  };

  const handleShipWeekChange = (value: number) => {
    setSelectedShipWeek(value);
  };

  const handleTypeChange = (value: string) => {
    if (value === 'stickers') {
      setIsSticker(true);
    } else {
      setIsSticker(false);
    }
  };

  const options: any = Array.from({ length: 50 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));

  const columns: any = [
    {
      title: 'Variety',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ship Week',
      dataIndex: 'week',
      render: () => <span>{Number(shippingWeek)}</span>,
      key: 'week',
    },
    {
      title: <span>{isSticker ? 'Total' : 'Total Quantity'}</span>,
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Breakdown',
      dataIndex: 'breakdown',
      key: 'breakdown',
    },
  ];

  const newColumns = !isSticker
    ? columns
    : columns.filter((column: { key: string }) => column.key !== 'breakdown');

  const getRecords = async () => {
    form
      .validateFields()

      .then(async (values: any) => {
        if (values.Type == 'bulkPickList') {
          setISBulkPL('bulkPickList');
          const payload = {
            orderFormId: selectedOrderForm,
            shipweek: selectedShipWeek,
            isStickers: isSticker,
            date: selectedDate,
          };
          setLoading(true);

          await api
            .post<never, any>(orderSticker, payload)
            .then((res: any) => {
              setDataSource(res.products);
              setShippingWeek(res.shipweek);
            })
            .catch((error: any) => {
              message.error(error?.response?.data?.message);
            });
          setLoading(false);
        } else {
          setISBulkPL('stickers');
          const payload = {
            orderFormId: selectedOrderForm,
            shipweek: selectedShipWeek,
            isStickers: isSticker,
            date: selectedDate,
          };
          setLoading(true);

          await api
            .post<never, any>(orderSticker, payload)
            .then((res: any) => {
              setDataSource(res.products);
              setShippingWeek(res.shipweek);
            })
            .catch((error: any) => {
              message.error(error?.response?.data?.message);
            });
          setLoading(false);
        }
      })

      .catch(() => {
        return false;
      });
  };

  const printPR = async () => {
    if (isBulkPL === '') {
      message.error('Please select data to be printed');
    } else {
      if (isBulkPL === 'bulkPickList') {
        setIsSticker(false);
      } else {
        setIsSticker(true);
      }
      let getPickListString = '';
      if (!isSticker) {
        getPickListString = ReactDOMServer.renderToStaticMarkup(
          <PackingReport dataSource={dataSource} shippingWeek={shippingWeek!} />,
        );
      } else {
        getPickListString = ReactDOMServer.renderToStaticMarkup(
          <Label dataSource={dataSource} shippingWeek={shippingWeek} />,
        );
      }

      const mywindow = window.open('', 'PRINT', 'height=1200,width=1200');

      if (mywindow) {
        mywindow.document.write(
          '<html> <head>     <link rel="preconnect" href="https://fonts.googleapis.com">     <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>     <link         href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"         rel="stylesheet">    <style>         @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");   @page { size: 101.6mm 25.4mm; margin: 0; }        body,         select,         option {             font-family: "Montserrat", sans-serif;         }         table {             width: 90%;             margin-left: 5%;             border-collapse: collapse;         }         th,         td {  padding: 2px;  text-align: center; }         #header {        /*     padding-top: 2%;             padding-bottom: 2%;             margin-bottom: 1.5%;              background:#f7ece2; */         }         #titlewrapper {             padding-top: 1%;             padding-bottom: 1%;             margin-left: 25%;             width: 50%;             text-align: center;             border: 2px solid black;         }         #title {             font-weight: 300;             font-size: 1.3em;         }         #date {             font-weight: 300;             font-size: 1em;         }       .align-left {             text-align: left;         }    .align-right {             text-align: right;         } .width-50 {             width: 50%;         } </style> </head>',
        );
        mywindow.document.write(getPickListString);

        mywindow.document.write(' </html>');

        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        // mywindow.close();
      }
    }
  };

  const exportToExcel = () => {
    if (dataSource.length) {
      const dataToExport = dataSource.map(({ quantity, name }) => ({ quantity, name }));
      const csvData = Papa.unparse(dataToExport, { header: true });
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvUrl = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvUrl;
      if (isSticker) {
        link.setAttribute('download', 'Stickers.csv');
      } else {
        link.setAttribute('download', 'Bulk Picklist.csv');
      }

      document.body.appendChild(link);
      link.click();
    } else {
      message.error('Please select data to be printed');
    }
  };

  const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedDate(dateString);
  };

  useEffect(() => {
    api
      .get<never, ProductLine[]>(getAllProductLines)
      .then((result: ProductLine[]) => {
        setProductTypes(result);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
  }, []);

  return (
    <div className='px-2'>
      <h3 className='title-grey'>Print</h3>
      <div className='print-card d-flex gap-5'>
        <Form form={form} layout='vertical' className='payment-form' onFinish={getRecords}>
          <Form.Item
            rules={[{ required: true, message: 'Please select a Product Line' }]}
            label='Product Line'
            name='ProductLine'
            className='payment-form-child'
          >
            <Select
              placeholder='Select Product Line'
              onChange={handleProductLineChange}
              value={selectedProductLine}
              loading={inlineLoading}
            >
              {productTypes.map((productType) => (
                <Select.Option key={productType.id} value={productType.id}>
                  {productType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: 'Please select a Year' }]}
            label='Year'
            name='Year'
            className='payment-form-child'
          >
            <Select
              disabled={selectedProductLine === null}
              placeholder='Select Year'
              onChange={handleYearChange}
              value={selectedYear}
              loading={inlineLoading}
            >
              {distinctYears.map((year) => (
                <Select.Option key={year.year} value={year.year}>
                  {year.year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: 'Please select an Order Form' }]}
            label='Order Form'
            name='OrderForm'
            className='payment-form-child'
          >
            <Select
              disabled={selectedYear === null}
              placeholder='Select Order Form'
              onChange={handleOrderFormChange}
              value={selectedOrderForm}
              loading={inlineLoading}
            >
              {orderFormsData.map((orderForm: FormsData) => (
                <Select.Option key={orderForm.id} value={orderForm.id}>
                  {orderForm.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: 'Please select a Ship Week' }]}
            label='Ship Week'
            name='ShipWeek'
            className='payment-form-child'
          >
            <Select
              disabled={selectedOrderForm === null}
              placeholder='Select Ship Week'
              onChange={handleShipWeekChange}
              value={selectedShipWeek}
              loading={inlineLoading}
            >
              {options.map((options: any) => (
                <Select.Option key={options.value} value={options.value}>
                  {options.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: 'Please select a Type' }]}
            label='Type'
            name='Type'
            className='payment-form-child'
          >
            <Select placeholder='Select Type' onChange={handleTypeChange} loading={inlineLoading}>
              <Select.Option value='bulkPickList'>Bulk PickList</Select.Option>
              <Select.Option value='stickers'>Stickers</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label='Date' name='date' className='payment-form-child'>
            <DatePicker onChange={handleDateChange} />
          </Form.Item>

          <Form.Item className='payment-form-child button-align'>
            <Button
              type='primary'
              className='payment-form-child d-flex align-items-center justify-content-center'
              onClick={getRecords}
            >
              <FileSearchOutlined />
              Fetch
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div>
        <div className='d-flex table-title-row py-2'>
          <span className='secondary-color'>
            <b>Result</b>
          </span>
          <span className='d-flex gap-3'>
            <Button
              type='primary'
              className='payment-form-child d-flex align-items-center'
              onClick={exportToExcel}
            >
              <Tooltip title={'Export'}>
                <FileExcelOutlined />
              </Tooltip>
              Export
            </Button>
            <Button
              type='primary'
              className='payment-form-child d-flex align-items-center'
              onClick={printPR}
            >
              <Tooltip title={'Print'}>
                <PrinterOutlined />
              </Tooltip>
              Print
            </Button>
          </span>
        </div>
        <Table
          pagination={{ pageSize: 100, showSizeChanger: false }}
          loading={loading}
          rowKey='ProductId'
          dataSource={dataSource}
          columns={newColumns}
        />
      </div>
    </div>
  );
};

PrintLabel.propTypes = {
  dataSource: propTypes.any,
  shippingWeek: propTypes.any,
};

export default PrintLabel;
