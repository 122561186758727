import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { useStepper } from '../../context/stepper-context';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';

interface WeekDropdownProps {
  startingWeek: number;
  endShippingWeek: number;
  disabled: boolean;
}

interface WeekData {
  weekNumber: number;
  startDate: Date;
  endDate: Date;
}

const getWeekDates = (weekNumber: number, year: number): WeekData => {
  const firstDayOfYear = new Date(year, 0, 1);
  const dayOfWeek = firstDayOfYear.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

  const startDate = new Date(year, 0, 1 + daysToAdd);
  const endDate = new Date(year, 0, 1 + daysToAdd + 6);

  return {
    weekNumber,
    startDate,
    endDate,
  };
};

const WeekDropdown: React.FC<WeekDropdownProps> = ({ startingWeek, endShippingWeek, disabled }) => {
  const weeks: JSX.Element[] = [];
  const [form] = useForm();
  const {
    stepTwoShippingWeek,
    setStepTwoShippingWeek,
    setStepTwoShippingYear,
    stepTwoShippingYear,
  } = useStepper();

  useEffect(() => {
    form.setFieldsValue({ shippingWeek: stepTwoShippingWeek ? stepTwoShippingWeek : null });
  }, []);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue: any = event;
    setStepTwoShippingWeek(String(selectedValue));
    const matches = selectedValue.match(
      /^(\d+)-(\d{4})-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
    );
    setStepTwoShippingYear(matches[2]);
  };
  let startWeek = Math.round(startingWeek / 10000);
  const endWeek = Math.round(endShippingWeek / 10000);
  const startYear = startingWeek % 10000;
  const endYear = endShippingWeek % 10000;

  const currentDate: any = new Date();
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);
  const today = moment();

  if (startYear == currentDate.getFullYear() && startWeek < weekNumber) {
    startWeek = weekNumber;
  }

  if (startYear !== endYear) {
    for (let i = startWeek; i <= 50; i++) {
      const { weekNumber, startDate, endDate } = getWeekDates(i, startYear);
      const optionValue = `${weekNumber}-${startDate.toISOString()}-${endDate.toISOString()}`;
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      const startDayOfWeek = moment(startDate);
      if (today < startDayOfWeek) {
        weeks.push(
          <Select.Option key={optionValue} value={optionValue}>
            {optionLabel}
          </Select.Option>,
        );
      }
    }
    for (let i = 1; i <= endWeek; i++) {
      const { weekNumber, startDate, endDate } = getWeekDates(i, endYear);

      if (weekNumber == 1) {
        startDate.setHours(5);
        startDate.setMinutes(30);
      }

      const optionValue = `${weekNumber}-${startDate.toISOString()}-${endDate.toISOString()}`;
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      const startDayOfWeek = moment(startDate);
      if (today < startDayOfWeek) {
        weeks.push(
          <Select.Option key={optionValue} value={optionValue}>
            {optionLabel}
          </Select.Option>,
        );
      }
    }
  } else {
    for (let i = startWeek; i <= endWeek; i++) {
      const { weekNumber, startDate, endDate } = getWeekDates(i, startYear);

      if (weekNumber == 1) {
        startDate.setHours(5);
        startDate.setMinutes(30);
      }
      const optionValue = `${weekNumber}-${startDate.toISOString()}-${endDate.toISOString()}`;
      const optionLabel = `Week ${weekNumber} (${moment(startDate).format('LL')} - ${moment(
        endDate,
      ).format('LL')})`;

      const startDayOfWeek = moment(startDate);
      if (today < startDayOfWeek) {
        weeks.push(
          <Select.Option key={optionValue} value={optionValue}>
            {optionLabel}
          </Select.Option>,
        );
      }
    }
  }

  return (
    <Form form={form}>
      <Form.Item name='shippingWeek' className='mb-0'>
        <Select
          placeholder='Select a Ship Week'
          onChange={handleDropdownChange}
          disabled={disabled}
        >
          {weeks}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default WeekDropdown;
