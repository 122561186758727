import React, { useState, useEffect } from 'react';
import { Button, Card, Input, Modal, message, Form, Spin } from 'antd';
import { ProductLine } from '../../schema/order';
import api from '../../config/axios';
import { confirmPassword, getOrderForms } from '../../config/api-routes';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../layout/spinner';
import { SettingOutlined } from '@ant-design/icons';

function Products() {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cards, setCards] = useState<ProductLine[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [maintenanceVisible, setMaintenanceVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [id, setId] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getOrderFormList();
  }, []);

  const getOrderFormList = () => {
    api
      .get<never, ProductLine[]>(getOrderForms)
      .then((res) => {
        setCards(res);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };

  const handleClick = (id: number) => {
    const card = cards.find((item) => id === item.id);
    if (card) {
      setId(card.id);
    }

    if (card?.uderMaintainenece) {
      setMaintenanceVisible(true);
    } else {
      if (card?.passProtected) {
        setVisible(true);
      } else {
        navigate(`/product/${id}`);
      }
    }
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const onCancelMaintenance = () => {
    setMaintenanceVisible(false);
  };

  const validatePassword = () => {
    form
      .validateFields()
      .then((value: any) => {
        setLoader(true);
        api
          .get<never, any>(confirmPassword + `/${id}?pass=${value.password}`)
          .then((res: any) => {
            if (res) {
              if (res.matched) {
                setLoader(false);
                navigate(`/product/${id}`);
                form.resetFields();
              } else {
                setLoader(false);
                form.setFields([
                  {
                    name: 'password',
                    errors: ['Incorrect password'],
                  },
                ]);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch(() => {
        return false;
      });
  };

  return (
    <div>
      <h3 className='title-grey'>Order Forms</h3>
      <div className='product-card-area py-2'>
        {cards.map((card) => (
          <Card
            hoverable
            bordered
            className='product-card'
            key={card.id}
            title={card.title}
            onClick={() => handleClick(card.id)}
          >
            <p>{card.description && card.description}</p>
          </Card>
        ))}
        <Modal
          open={visible}
          title={'This form is password protected'}
          onCancel={onCancel}
          destroyOnClose
          footer={[
            <Button key='no' onClick={onCancel}>
              Cancel
            </Button>,
            <Button loading={loader} key='ok' type='primary' onClick={validatePassword}>
              Validate Password
            </Button>,
          ]}
        >
          <Form form={form}>
            <Form.Item
              name='password'
              label='Password'
              rules={[
                {
                  required: true,
                  message: 'Please enter Password',
                },
              ]}
            >
              <Input.Password placeholder='Enter Password' type='password' />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={maintenanceVisible}
          onCancel={onCancelMaintenance}
          destroyOnClose
          title={'This form is under Maintenance'}
          footer={[
            <Button key='ok' type='primary' onClick={onCancelMaintenance}>
              Ok
            </Button>,
          ]}
        >
          <div className='text-center py-3'>
            <SettingOutlined className='secondary-color' style={{ fontSize: '50px' }} />
            <SettingOutlined spin className='primary-color' style={{ fontSize: '20px' }} />
            <SettingOutlined className='secondary-color' style={{ fontSize: '30px' }} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Products;
