import React from 'react';
//import PersonalInfo from './personalInfo';
import AddressInfo from './addressInfo';
import { Col } from 'antd';

function Profile() {
  return (
    <div className='profile-cards'>
      {/* <span>Personal Information</span>
      <Col xs={24} sm={24} md={12} lg={12}>
        <PersonalInfo />
      </Col> */}

      <Col span={12} offset={6}>
        {/* <span>Billing Address</span> */}
        <AddressInfo />
      </Col>
    </div>
  );
}

export default Profile;
