import React from 'react';
import { Product, ShippingDetails } from '../../../schema/order';
import moment from 'moment';

type PickListProps = {
  productDetails: Product[];
  shippingDetails: ShippingDetails;
  deliveryNotes: string;
  fallOrders?: any;
  isHeated: any;
};

const PickList = ({
  productDetails,
  shippingDetails,
  deliveryNotes,
  fallOrders,
  isHeated
}: PickListProps) => {
  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }
  const getWeekDates = (weekNumber: number): WeekData => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const currentYear = shippingDetails.shippingWeek % 10000;

    const firstDayOfYear = new Date(currentYear, 0, 1);

    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek;

    const startDate = new Date(currentYear, 0, 1 + daysToAdd);
    const endDate = new Date(currentYear, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };
  const shippingWeek = getWeekDates(shippingDetails.shippingWeek / 10000);
  return (
    <body>
      <br />

      <div>
        <div>
          <table>
            <tr>
              <th colSpan={6}>Order for : {shippingDetails.shippingAddressBusiness}</th>
            </tr>
            <tr>
              {/* <td colSpan={3}>Name</td> */}
              <td colSpan={3}>{shippingDetails.shippingAddressName}</td>
            </tr>
            <tr>
              {/* <td colSpan={3}>Email</td> */}
              <td colSpan={3}>{shippingDetails.shippingEmail}</td>
            </tr>
            <tr>
              {/* <td colSpan={3}>Phone</td> */}
              <td colSpan={3}>{shippingDetails.shippingAddressPhone}</td>
            </tr>
            <tr>
              {/* <td colSpan={3}>Address</td> */}
              <td colSpan={3}>
                {shippingDetails.shippingAddressStreetAddress},{' '}
                {shippingDetails.shippingAddressAdddressLine2},{' '}
                {shippingDetails.shippingAddressCity}, {shippingDetails.shippingAddressPostalCode}
              </td>
            </tr>
            <tr>
              {/* <td colSpan={3}>Province</td> */}
              <td colSpan={3}>{shippingDetails.shippingAddressProvince}</td>
            </tr>
          </table>
        </div>
        <br></br>
        <div>
          <table>
            <thead>
              <tr>
                <th>Variety</th>
                <th>Type</th>
                <th>Shipment</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Final Pack</th>
              </tr>
            </thead>
            <tbody>
              {productDetails
                .filter((item) => item.quantity !== 0)
                .map((item, index) => (
                  <tr key={index}>
                    <td style={{textAlign: 'left'}}>{item.name}</td>
                    <td>{item.type}</td>
                    <td>{item.shipment?.toUpperCase()}</td>
                    <td>{item.size}</td>
                    <td>{item.quantity}</td>
                    <td></td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Delivery Notes</td>
                <td>{deliveryNotes}</td>
              </tr>
              <tr>
                <td>Requested Ship Week</td>
                <td>
                  {shippingWeek.weekNumber.toFixed()} ({moment(shippingWeek.startDate).format('LL')}{' '}
                  - {moment(shippingWeek.endDate).format('LL')})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        {isHeated && (<div className='w-90 mx-5 fall-orders'><b>This order is requested for Heat Packs</b></div>)}
        <br />
        <div className='w-90 mx-5 fall-orders'>
          {fallOrders && fallOrders.map((order: any, index: any) => <div key={index}>{order}</div>)}
        </div>
      </div>
    </body>
  );
};

export default PickList;
