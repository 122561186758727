class config {
  getHost = () => {
    const host = window.location.host;
    console.log('window.location.host ---- ',window.location.host);
    // return 'https://afg8bnahah.execute-api.ca-central-1.amazonaws.com/qa/api/v1';
    if (host.substring(Number(host), 9) === 'localhost') {
      return 'https://afg8bnahah.execute-api.ca-central-1.amazonaws.com/qa/api/v1';
    } else if (host.includes('dev')) {
      return 'https://65y7esj2ve.execute-api.ca-central-1.amazonaws.com/dev/api/v1';
    } else if (host.includes('qa')) {
      return 'https://afg8bnahah.execute-api.ca-central-1.amazonaws.com/qa/api/v1';
    } else {
      return 'https://th74qcn357.execute-api.ca-central-1.amazonaws.com/prod/api/v1';
    }
  };
}

export default new config();