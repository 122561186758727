import {
  Button,
  Card,
  Checkbox,
  Collapse,
  FloatButton,
  Form,
  Input,
  Popconfirm,
  Select,
  Switch,
  Tooltip,
  Upload,
  message,
  Modal,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  getAllProductLines,
  getDistinctYear,
  getOrderFormsByYear,
  getOrderFormForAdmin,
  updateOrderFormForAdmin,
  productDiscounts,
  productFiles,
  productTiers,
  inventory,
  deleteInventory,
} from '../../config/api-routes';
import api from '../../config/axios';
import Spinner from '../../layout/spinner';
import {
  AdminOrderForm,
  Discounts,
  Files,
  OrderTiers,
  OrderWeekOption,
  Product,
  Reports,
  Years,
} from '../../schema/order';
import GetYearObjects from '../../common/getPrevAndNextYears';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Table, { ColumnsType } from 'antd/es/table';
import ProductInventoryTable from './ProductInventoryTable';
import EditAddDiscounts from './editAddDiscounts';
import EditAddFiles from './editAddFiles';
import EditAddTiers from './editAddTiers';
import AddOrderInventory from './addOrderInventory';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
  UploadOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import Papa from 'papaparse';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function OrderInventory() {
  const [form] = Form.useForm();
  const addEditDiscountPopupRef = useRef<typeof EditAddDiscounts>(null);
  const addEditFilesPopupRef = useRef<typeof EditAddFiles>(null);
  const addEditTiersPopupRef = useRef<typeof EditAddTiers>(null);
  const addOrderInventoryPopupRef = useRef<typeof AddOrderInventory>(null);
  const [productTypes, setProductTypes] = useState([]);
  const [startShippingWeeks, setStartShippingWeeks] = useState<WeekOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [inlineLoading, setInlineLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [discountsLoading, setDiscountsLoading] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const [tiersLoading, setTiersLoading] = useState(false);
  const [inventoryLoading, setInventoryLoading] = useState(false);

  const [toggleOrderDetailsCard, setToggleOrderDetailsCard] = useState(false);

  const [distinctYears, setDistinctYears] = useState([]);

  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedOrderFormId, setSelectedOrderFormId] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState<OrderWeekOption>();

  const [selectedDistinctYear, setSelectedDistinctYear] = useState<Years | ''>();
  const [orderFormsData, setOrderFormsData] = useState<any[]>([]);

  const [yearsList, setYears] = useState<Years[]>([]);

  const [discountAmountBased, setDiscountAmountBased] = useState<boolean>();
  const [tierQuantityBased, setTierQuantityBased] = useState<boolean>();
  const [sameTiers, setSameTiers] = useState<boolean>();

  const [sameTiersCheck, setSameTiersCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load
  const [tiersQuantityCheck, setTierQuantityCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load
  const [discountAmountCheck, setDiscountAmountCheck] = useState<boolean>(false); // handle this check for call api only on checkbox event and prevent on page load

  const [mode, setMode] = useState('');

  const [discountPopupVisible, setDiscountPopupVisible] = useState<boolean>(false);
  const [filesPopupVisible, setFilesPopupVisible] = useState<boolean>(false);
  const [orderTiersPopupVisible, setOrderTiersPopupVisible] = useState<boolean>(false);
  const [orderInventoryPopupVisible, setOrderInventoryPopupVisible] = useState<boolean>(false);
  const [deletePopUpVisible, setDeletePopUpVisible] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);

  const [discountEdit, setDiscountsEdit] = useState<Discounts | null>(null);
  const [filesEdit, setFilesEdit] = useState<Files | null>(null);
  const [orderTiersEdit, setOrderTiersEdit] = useState<OrderTiers | null>(null);

  const [discounts, setDiscounts] = useState<Discounts[]>([]);
  const [files, setFiles] = useState<Files[]>([]);
  const [tiers, setTiers] = useState<OrderTiers[]>([]);
  const [inventories, setInventory] = useState<Product[]>([]);

  const [notesHtml, setNotesHtml] = useState<string>('');
  const quillRef = useRef<ReactQuill | null>(null);

  const [popupName, setPopupName] = useState('');
  const [adminOrderForms, setAdminOrderForm] = useState<AdminOrderForm>({
    id: 0,
    title: '',
    minOrderAmt: 0,
    shippingWeek: 0,
    endShippingWeek: 0,
    deposit: 0,
    password: '',
    year: 0,
    notes: '',
    description: '',
    isDiscountAmountBased: false,
    isTierQuantityBased: false,
    isTierSameForAll: false,
    isActive: false,
    isUnderMaintenance: false,
    discounts: [],
    orderTiers: [],
    files: [],
    products: [],
    reports: [],
    allowHeated: false,
    zeroQuantity: false,
    saleStarted: false,
  });
  const navigate = useNavigate();

  //Get Current week number and set to generate week options
  const currentDate: any = new Date();
  const startDate: any = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil(days / 7);
  const { Panel } = Collapse;
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);

  const togglePanelCollapse = () => {
    setIsPanelCollapsed(!isPanelCollapsed);
  };
  const panelHeaderText = isPanelCollapsed ? 'See More' : 'Collapse';

  useEffect(() => {
    getAllProductTypes();
    getPrevAndNextYear();
    setStartShippingWeeks(generateWeekOptions(new Date().getFullYear(), 1));
  }, []);

  useEffect(() => {
    if (selectedOrderFormId > 0) {
      getDiscounts();
      getFiles();
      getOrderTiers();
      getInventory();
    }
  }, [selectedOrderFormId]);

  const getPrevAndNextYear = () => {
    const yearArray = GetYearObjects();
    setYears(yearArray);
  };

  const getDiscounts = async () => {
    setDiscountsLoading(true);
    await api
      .get(`${productDiscounts}/${selectedOrderFormId}`)
      .then((result: any) => {
        setDiscounts(result);
        setDiscountsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDiscountsLoading(false);
      });
  };

  const getFiles = async () => {
    setFilesLoading(true);
    await api
      .get(`${productFiles}/${selectedOrderFormId}`)
      .then((result: any) => {
        setFiles(result);
        setFilesLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setFilesLoading(false);
      });
  };

  const getInventory = async () => {
    setInventoryLoading(true);
    await api
      .get(`${inventory}/${selectedOrderFormId}`)
      .then((result: any) => {
        setInventory(result);
        setInventoryLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setInventoryLoading(false);
      });
  };

  const getOrderTiers = async () => {
    setTiersLoading(true);
    await api
      .get(`${productTiers}/${selectedOrderFormId}`)
      .then((result: any) => {
        setTiers(result);
        setTiersLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTiersLoading(false);
      });
  };
  const getAllProductTypes = async () => {
    setLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const onProductLineChange = (item: any, event: any) => {
    if (event && event.id) {
      const productId = event.id;
      setSelectedProductId(productId);
      getDistinctYears(productId);
      setSelectedDistinctYear(undefined);
      setToggleOrderDetailsCard(false);
      setSelectedOrderFormId(0);

      setOrderFormsData([]);
    } else {
      setSelectedDistinctYear(undefined);
      setOrderFormsData([]);
      setToggleOrderDetailsCard(false);
    }
  };

  const onDistinctYearChange = (item: any, event: any) => {
    if (event) {
      const selectedYear = event.value;
      setSelectedDistinctYear(event);
      getOrderForms(selectedProductId, selectedYear);

      setOrderFormsData([]);
      setToggleOrderDetailsCard(false);
    } else {
      setSelectedDistinctYear(undefined);
      setOrderFormsData([]);
      setToggleOrderDetailsCard(false);
    }
  };

  const getOrderForms = async (productId: any, selectedYear: any) => {
    setInlineLoading(true);
    await api
      .get<never, any>(`${getOrderFormsByYear}/${productId}?year=${selectedYear}`)
      .then((res: any) => {
        const orderFormResponse = res;
        const orderFormdd = [];
        for (let i = 0; i < orderFormResponse.length; i++) {
          const object = orderFormResponse[i];
          orderFormdd.push({
            id: object.id,
            value: object.id,
            title: object.title,
            key: object.id,
            label: object.title,
          });
        }

        setOrderFormsData(orderFormdd);
        setInlineLoading(false);
        if (Array.isArray(orderFormResponse)) {
          const orderFormId = orderFormdd[0].id;
          setSelectedOrderFormId(orderFormId);
          if (orderFormResponse.length == 1) {
            fetchOrderForm(orderFormId);
            setToggleOrderDetailsCard(true);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        setInlineLoading(false);
      });
  };

  const fetchOrderForm = (orderFormId: any) => {
    setFormLoading(true);
    api
      .get<never, AdminOrderForm>(`${getOrderFormForAdmin}/${orderFormId}`)
      .then((res: AdminOrderForm) => {
        setAdminOrderForm(res);

        const shippingWeekId = res.shippingWeek.toString();
        const startYear = parseInt(shippingWeekId.substring(shippingWeekId.length - 4));
        const week = Math.round(res.shippingWeek / 10000);
        const endWeekValues = generateWeekOptions(startYear, week + 1);

        setEndWeekOptions(endWeekValues);

        const shippingWeek = startShippingWeeks.filter((s: any) => s.id == res.shippingWeek);
        const endShippingWeek = endWeekValues.filter((e: any) => e.id == res.endShippingWeek);

        const year = yearsList.filter((year) => year.id == res.year);
        setFormLoading(false);

        if (sameTiers === undefined) setSameTiers(res.isTierSameForAll);
        if (discountAmountBased === undefined) setDiscountAmountBased(res.isDiscountAmountBased);
        if (tierQuantityBased === undefined) setTierQuantityBased(res.isTierQuantityBased);

        form.setFieldsValue({
          title: res.title,
          minOrderAmt: res.minOrderAmt,
          shippingWeek: shippingWeek,
          endShippingWeek: endShippingWeek,
          description: res.description,
          deposit: res.deposit,
          password: res.password,
          year: year,
          notes: res.notes,
          isActive: res.isActive,
          isUnderMaintenance: res.isUnderMaintenance,
          allowHeated: res.allowHeated,
          zeroQuantity: res.zeroQuantity,
        });
        setNotesHtml(res.notes);
      })
      .catch((error: any) => {
        setFormLoading(false);
        console.log(error);
      });
  };

  const getDistinctYears = (selectedProductId: any) => {
    setInlineLoading(true);
    api
      .get(`${getDistinctYear}/${selectedProductId}`)
      .then((result: any) => {
        const response = result;
        const transformedYearList = response.map((item: any, index: any) => ({
          id: index + 1,
          value: Object.values(item)[0],
        }));

        // If current year found in list , set it selected
        const currentDate = new Date();
        const currentYear = transformedYearList.filter(
          (item: any) => item.value == currentDate.getFullYear(),
        );

        if (currentYear.length) {
          setSelectedDistinctYear(currentYear);
          getOrderForms(selectedProductId, currentYear[0].value);
        }

        setDistinctYears(transformedYearList);
        setInlineLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setInlineLoading(false);
      });
  };

  const addDiscounts = () => {
    setDiscountsEdit(null);
    setPopupName('Discount');
    setMode('Add');
    setDiscountPopupVisible(true);
  };
  const editDiscount = (record: Discounts | null) => {
    setDiscountsEdit(record);
    setPopupName('Discount');
    setMode('Edit');
    setDiscountPopupVisible(true);
  };

  const addFiles = () => {
    setFilesEdit(null);
    setPopupName('File');
    setMode('Add');
    setFilesPopupVisible(true);
  };
  const editFiles = (record: Files | null) => {
    setFilesEdit(record);
    setPopupName('File');
    setMode('Edit');
    setFilesPopupVisible(true);
  };

  const addOrderTiers = () => {
    setOrderTiersEdit(null);
    setMode('Add');
    setOrderTiersPopupVisible(true);
  };
  const editOrderTiers = (record: OrderTiers | null) => {
    setOrderTiersEdit(record);
    setMode('Edit');
    setOrderTiersPopupVisible(true);
  };

  const addOrderInventory = () => {
    setMode('Add');
    setOrderInventoryPopupVisible(true);
  };

  const handleCancel = () => {
    setDiscountPopupVisible(false);
    setFilesPopupVisible(false);
    setOrderTiersPopupVisible(false);
    setOrderInventoryPopupVisible(false);

    setDiscountsEdit(null);
    setFilesEdit(null);
    setOrderTiersEdit(null);
    setPopupName('');
  };

  const handleSaveDiscount = (item: Discounts) => {
    const payload = {
      discountValue: item.discValue,
      threshold: item.minQty,
    };

    setDiscountsLoading(true);

    mode == 'Add'
      ? api
          .post(`${productDiscounts}/${adminOrderForms?.id}`, payload)
          .then(() => {
            message.success('Discount added successfully');
            getDiscounts();
            setDiscountPopupVisible(false);
            setDiscountsLoading(false);
          })
          .catch((error) => {
            setDiscountsLoading(false);
            message.error(error?.response?.data?.message);
          })
      : api
          .put(`${productDiscounts}/${item?.id}`, payload)
          .then(() => {
            message.success('Discount updated successfully');
            getDiscounts();
            setDiscountPopupVisible(false);
            setDiscountsLoading(false);
          })
          .catch((error) => {
            setDiscountsLoading(false);
            message.error(error?.response?.data?.message);
          });
  };
  const deleteDiscount = (id: any) => {
    setDiscountsLoading(true);
    api
      .delete(`${productDiscounts}/${id}`)
      .then((res: any) => {
        setDiscountsLoading(false);
        message.success(res?.message);
        getDiscounts();
      })
      .catch((error) => {
        setDiscountsLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleSaveFiles = (item: Files) => {
    setFilesLoading(true);
    mode == 'Add'
      ? api
          .post(`${productFiles}/${adminOrderForms?.id}`, item)
          .then(() => {
            setFilesPopupVisible(false);
            setFilesLoading(false);
            message.success('File added successfully');
            getFiles();
          })
          .catch((error) => {
            setFilesLoading(false);
            message.error(error?.response?.data?.message);
          })
      : api
          .put(`${productFiles}/${item?.id}`, item)
          .then(() => {
            setFilesPopupVisible(false);
            setFilesLoading(false);
            message.success('File updated successfully');
            getFiles();
          })
          .catch((error) => {
            setFilesLoading(false);
            message.error(error?.response?.data?.message);
          });
  };
  const deleteFile = (id: any) => {
    setFilesLoading(true);
    api
      .delete(`${productFiles}/${id}`)
      .then((res: any) => {
        message.success(res?.message);
        setFilesLoading(false);
        getFiles();
      })
      .catch((error) => {
        setFilesLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleSaveOrderTiers = (item: OrderTiers) => {
    if (mode == 'Add') {
      setTiersLoading(true);
      if (tiers && sameTiers && tiers.length > 0) {
        item.tier1Qty = tiers[0].tier1Qty;
        item.tier2Qty = tiers[0].tier2Qty;
        item.tier3Qty = tiers[0].tier3Qty;
      }

      api
        .post(`${productTiers}/${adminOrderForms?.id}`, item)
        .then(() => {
          setOrderTiersPopupVisible(false);
          message.success('Order tier added successfully');
          setTiersLoading(false);
          getOrderTiers();
        })
        .catch((error) => {
          setTiersLoading(false);
          message.error(error?.response?.data?.message);
        });
    } else {
      setTiersLoading(true);
      api
        .put(`${productTiers}/${item?.id}?orderform=${adminOrderForms?.id}`, {
          ...item,
          isTierSameForAll: sameTiers,
        })
        .then(() => {
          setOrderTiersPopupVisible(false);
          setTiersLoading(false);
          message.success('Order tier updated successfully');
          getOrderTiers();
        })
        .catch((error) => {
          setTiersLoading(false);
          message.error(error?.response?.data?.message);
        });
    }
  };
  const deleteOrderTier = (id: any) => {
    setTiersLoading(true);
    api
      .delete(`${productTiers}/${id}`)
      .then((res: any) => {
        setTiersLoading(false);
        message.success(res?.message);
        getOrderTiers();
      })
      .catch((error) => {
        setTiersLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleSaveOrderInventory = (item: Product) => {
    setInventoryLoading(true);
    api
      .post(`${inventory}/${selectedOrderFormId}`, item)
      .then(() => {
        message.success('Inventory item saved successfully');
        setOrderInventoryPopupVisible(false);
        setInventoryLoading(false);
        getInventory();
      })
      .catch((error) => {
        setInventoryLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  // -------------------------------------------------------------------------------------------
  interface WeekOption {
    id: string;
    value: string;
    key: string;
  }
  const [startWeek, setStartWeek] = useState<string | undefined>();
  const [endWeekOptions, setEndWeekOptions] = useState<Array<WeekOption>>([]);
  const [endWeekValue /* setEndWeekValue */] = useState<WeekOption | null>();

  const generateWeekOptions = (year: number, starWeek: number): WeekOption[] => {
    const weeks: WeekOption[] = [];

    const totalWeeks = 50; // Removed two end weeks

    for (let week = starWeek; week <= totalWeeks; week++) {
      const weekId = `${week}${year - 1}`;
      const weekLabel = `Week ${week} - ${year - 1}`;
      weeks.push({ id: weekId, value: weekLabel, key: weekId });
    }

    for (let week = starWeek; week <= totalWeeks; week++) {
      const weekId = `${week}${year}`;
      const weekLabel = `Week ${week} - ${year}`;
      weeks.push({ id: weekId, value: weekLabel, key: weekId });
    }
    for (let week = 1; week <= totalWeeks; week++) {
      const weekId = `${week}${year + 1}`;
      const weekLabel = `Week ${week} - ${year + 1}`;
      weeks.push({ id: weekId, value: weekLabel, key: weekId });
    }

    return weeks;
  };

  const handleStartWeekChange = (week: any) => {
    const key = week.value;
    setStartWeek(key);
    const year = parseInt(key.split(' ')[3]);
    const selectedStartWeek = parseInt(key.split(' ')[1]);
    const weeksForEndSelect = generateWeekOptions(year, selectedStartWeek + 1);

    setEndWeekOptions(weeksForEndSelect);
    form.setFieldsValue({ endShippingWeek: null });
  };

  const reportColumns: ColumnsType<Reports[]> = [
    {
      title: 'Stock Total',
      key: 'totalStock',
      align: 'center',
      render: (text: any, record: any) => (
        <h3>
          {Number(record.totalStock) > 0
            ? Number(record.totalStock) + Number(record.totalOrdered)
            : 0}
        </h3>
      ),
    },
    {
      title: 'Remainder Total',
      dataIndex: 'totalStock',
      key: 'totalStock',
      align: 'center',
      render: (text: any, record: any) => (
        <h3>{Number(record.totalStock) > 0 ? Number(record.totalStock) : 0}</h3>
      ),
    },
    {
      title: '% Sold',
      key: 'percentStock',
      align: 'center',
      render: (text: any, record: any) => (
        <h3>
          {Number(record.totalOrdered) > 0
            ? (
                (Number(record.totalOrdered) /
                  (Number(record.totalOrdered) + Number(record.totalStock))) *
                100
              ).toFixed(2)
            : 0}
          %
        </h3>
      ),
    },
    {
      title: '% Remaining',
      key: 'percentRemaining',
      align: 'center',
      render: (text: any, record: any) => (
        <h3>
          {' '}
          {Number(record.totalStock) > 0
            ? (
                (Number(record.totalStock) /
                  (Number(record.totalOrdered) + Number(record.totalStock))) *
                100
              ).toFixed(2)
            : 0}
          %
        </h3>
      ),
    },
  ];

  const discountColumns: ColumnsType<Discounts> = [
    {
      title: 'Discount Value (%)',
      dataIndex: 'discValue',
      key: 'discValue',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: discountAmountBased ? 'Amount ($)' : 'Quantity',
      dataIndex: 'minQty',
      key: 'minQty',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      width: '200px',
      align: 'center',
      render: (_, record: Discounts | null) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editDiscount(record)} />
          </Tooltip>

          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => deleteDiscount(record ? record.id : 0)}
          >
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const filesColumns: ColumnsType<Files> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      width: '200px',
      align: 'center',
      render: (text) => <span style={{ wordBreak: 'break-all' }}>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '200px',
      align: 'center',
      render: (_, record: Files | any) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editFiles(record)} />
          </Tooltip>

          <Popconfirm title='Sure to delete?' onConfirm={() => deleteFile(record ? record.id : 0)}>
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const orderTiersColumns: ColumnsType<OrderTiers> = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'id',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    !sameTiers && {
      title: 'Tier 1 Quantity',
      dataIndex: 'tier1Qty',
      key: 'tier1Qty',
      width: '200px',
      align: 'center',
      render: (text: any) => <span>{text}</span>,
    },
    !sameTiers && {
      title: 'Tier 2 Quantity',
      dataIndex: 'tier2Qty',
      key: 'tier2Qty',
      width: '200px',
      align: 'center',
    },
    !sameTiers && {
      title: 'Tier 3 Quantity',
      dataIndex: 'tier3Qty',
      key: 'tier3Qty',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Max Threshold',
      dataIndex: 'maxThreshold',
      key: 'maxThreshold',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Multiple',
      dataIndex: 'multiple',
      key: 'multiple',
      width: '200px',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      width: '12%',
      align: 'center',
      render: (_: any, record: OrderTiers | any) => (
        <div className='d-flex gap-4 justify-content-center'>
          <Tooltip title='Edit'>
            <EditOutlined onClick={() => editOrderTiers(record)} />
          </Tooltip>

          <Popconfirm
            title='Sure to delete?'
            onConfirm={() => deleteOrderTier(record ? record.id : 0)}
          >
            <Tooltip title='Delete'>
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ].filter(Boolean) as ColumnsType<OrderTiers>;

  const onDiscountChange = (e: CheckboxChangeEvent) => {
    setDiscountAmountBased(e.target.checked);
    setDiscountAmountCheck(!discountAmountCheck);
  };

  useEffect(() => {
    if (selectedOrderFormId > 0) {
      handleOrderFormSubmit();
    }
  }, [sameTiersCheck]);
  useEffect(() => {
    if (selectedOrderFormId > 0) {
      handleOrderFormSubmit();
    }
  }, [discountAmountCheck]);
  useEffect(() => {
    if (selectedOrderFormId > 0) {
      handleOrderFormSubmit();
    }
  }, [tiersQuantityCheck]);

  const handleInventoryDelete = (id: React.Key) => {
    const newData = inventories.filter((item) => id !== item.id);
    setInventory(newData);

    setInventoryLoading(true);
    api
      .delete(`${inventory}/${id}?orderform=${selectedOrderFormId}`)
      .then((res: any) => {
        message.success(res?.message);
        setInventoryLoading(false);
        getInventory();
      })
      .catch((error) => {
        setInventoryLoading(false);
        message.error(error?.response?.data?.message);
        getInventory();
      });
  };

  const handleTableUpdate = (newTableRowData: Product) => {
    setInventoryLoading(true);
    api
      .put(`${inventory}/${newTableRowData.id}`, newTableRowData)
      .then(() => {
        message.success('Inventory item updated successfully');
        setInventoryLoading(false);
        getInventory();
      })
      .catch((error) => {
        setInventoryLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleTableUpdateWithCsv = (tableData: Product[]) => {
    api
      .put(`${inventory}`, { products: tableData })
      .then(() => {
        message.success('Inventory item updated successfully');
        getInventory();
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
      });
  };

  const orderFormChange = (event: any) => {
    if (event) {
      const selectedOrderFormId = event.key;
      setSelectedOrderFormId(selectedOrderFormId);
      fetchOrderForm(selectedOrderFormId);
      setToggleOrderDetailsCard(true);
    }
  };

  const handleOrderFormSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const updatedValues: any = form.getFieldsValue();

        let selectedShippingWeek;
        let selectedEndShippingWeek;

        if (updatedValues.shippingWeek instanceof Array) {
          // Handle multiple selected values (for mode="multiple")
          selectedShippingWeek = updatedValues.shippingWeek[0].key;
        } else {
          // Handle single selected value
          selectedShippingWeek = updatedValues.shippingWeek.key;
        }

        if (updatedValues.endShippingWeek instanceof Array) {
          selectedEndShippingWeek = updatedValues.endShippingWeek[0].key;
        } else {
          selectedEndShippingWeek = updatedValues.endShippingWeek.key;
        }

        const payload = {
          title: updatedValues.title,
          minOrderAmt: updatedValues.minOrderAmt,
          shipweek: Number(selectedShippingWeek),
          endShippingWeek: Number(selectedEndShippingWeek),
          deposit: Number(updatedValues.deposit),
          password: updatedValues.password,
          notes: updatedValues.notes,
          description: updatedValues.description,
          isDiscountAmountBased: discountAmountBased ? 1 : 0,
          isTierSameForAll: sameTiers ? 1 : 0,
          isTierQuantityBased: tierQuantityBased ? 1 : 0,
          isActive: updatedValues.isActive ? 1 : 0,
          isUnderMaintenance: updatedValues.isUnderMaintenance ? 1 : 0,
          allowHeated: updatedValues.allowHeated ? 1 : 0,
          zeroQuantity: updatedValues.zeroQuantity ? 1 : 0,
        };
        setFormLoading(true);
        api
          .put(`${updateOrderFormForAdmin}/${adminOrderForms?.id}`, payload)
          .then((res: any) => {
            message.success(res.message);
            fetchOrderForm(selectedOrderFormId);
          })
          .catch((error) => {
            message.error(error?.response?.data?.message);
          });
      })
      .catch(() => {
        setFormLoading(false);
        return false;
      });
  };

  const handleNegativeValidator = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value < 0) {
        reject('Negative values are not allowed');
      } else {
        resolve();
      }
    });
  };

  const handleMax100 = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value > 100) {
        reject('Input value should be max 100');
      } else {
        resolve();
      }
    });
  };

  const openDeletePopUp = () => {
    setDeletePopUpVisible(true);
  };

  const handleDeleteCancel = () => {
    setDeletePopUpVisible(false);
  };

  const handleDeleteOk = async () => {
    setDeleteLoader(true);

    await api
      .delete(`${deleteInventory}/${adminOrderForms.id}`)
      .then((res: any) => {
        setDeleteLoader(false);
        setDeletePopUpVisible(false);
        navigate('/');
        message.success(res?.message);
      })
      .catch((error) => {
        setDeleteLoader(false);
        setDeletePopUpVisible(false);
        message.error(error?.response?.data?.message);
      });
  };

  const handleExportCsv = () => {
    const filteredData = inventories.map((row) => {
      delete row.isActive;
      delete row.createdAt;
      delete row.updatedAt;
      delete row.createdBy;
      delete row.updatedBy;
      delete row.overallTotal;
      delete row.totalScrap;
      delete row.visibility;
      return row;
    });

    const csvData = Papa.unparse(filteredData, { header: true });
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', 'inventory.csv');
    document.body.appendChild(link);
    link.click();
  };

  const validateWhiteSpace = (_: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Input cannot consist only of spaces');
    }
    return Promise.resolve();
  };

  const cloneForm = () => {
    if (selectedProductId <= 0 || !selectedDistinctYear || selectedOrderFormId <= 0) {
      message.error('Please select form');
      return;
    }

    const targetUrl = `/new-order-form/${selectedProductId}/${selectedDistinctYear}/${selectedOrderFormId}`;
    navigate(targetUrl);
  };

  const props: UploadProps = {
    beforeUpload: (file: RcFile) => {
      const isCSV = file.type === 'text/csv';
      if (!isCSV) {
        message.error(`${file.name} is not a valid csv file`);
      } else {
        const dataColumns = [
          'name',
          'size',
          'type',
          'subType',
          'tier1Price',
          'tier2Price',
          'tier3Price',
          'totalOrdered',
          'totalStock',
          'imageUrl',
          'notes',
        ];
        Papa.parse(file, {
          complete: (result) => {
            if (result.data && result.data.length > 0) {
              const parsedData: any = result.data;
              const columns =
                parsedData.length > 0 ? Object.keys(parsedData[0]).map((header) => header) : [];

              if (columns && columns.length > 0) {
                let isValid = true;
                for (let i = 0; i < dataColumns.length; i++) {
                  if (!columns.includes(dataColumns[i])) {
                    message.error(`Error in file!, "${dataColumns[i]}" is misspelled or missing.`);
                    isValid = false;
                    break;
                  }
                }
                if (isValid) {
                  handleTableUpdateWithCsv(parsedData);
                }
              } else {
                message.error('Invalid CSV file');
              }
            } else {
              message.error('Unable to parse the CSV file.');
            }
          },
          header: true, // Parse the first row as headers
          skipEmptyLines: true, // Skip empty lines in the CSV file
        });
      }
      return isCSV || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log('Info', info.fileList);
    },
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    ); // Display a loader while loading
  }

  return (
    <div className='order-main px-2 pb-4'>
      <div className='d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
        <h3 className='title-grey'>Inventory</h3>
        <span className='d-flex gap-2'>
          <Button
            className='d-flex align-items-center'
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => navigate('/new-order-form')}
          >
            Add New
          </Button>
        </span>
      </div>
      <Card className='mb-3 top-filter-row'>
        <div className='d-flex flex-wrap gap-2' style={{ justifyContent: 'center' }}>
          <div className='dropdown-container d-flex flex-column fw-600'>
            <span className='pb-2'>
              <label>Product Line</label>
            </span>
            <Select
              placeholder={'Select Product Line'}
              allowClear
              options={productTypes}
              style={{ width: '400px' }}
              loading={inlineLoading}
              labelInValue
              onChange={(value, event) => onProductLineChange(value, event)}
            ></Select>
          </div>

          <div className='dropdown-container d-flex flex-column fw-600'>
            <span className='pb-2'>
              <label>Year</label>
            </span>
            <Select
              disabled={inlineLoading}
              placeholder={'Select Year'}
              allowClear
              value={selectedDistinctYear}
              options={distinctYears}
              style={{ width: '400px' }}
              loading={inlineLoading}
              labelInValue
              onChange={(value, event) => onDistinctYearChange(value, event)}
            ></Select>
          </div>

          {_.size(orderFormsData) > 1 ? (
            <div className='dropdown-container d-flex flex-column fw-600'>
              <span className='pb-2'>
                <label>Order Form</label>
              </span>
              <Select
                placeholder={'Select Order Form'}
                labelInValue
                allowClear
                options={orderFormsData}
                onChange={orderFormChange}
                style={{ width: '400px' }}
              ></Select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Card>
      {/* Inventory Table Start*/}
      {toggleOrderDetailsCard && (
        <Card>
          <div>
            <div className='d-flex table-title-row'>
              <h5>Inventory</h5>
              <div className='d-flex align-items-center gap-2'>
                <Upload {...props} showUploadList={false} maxCount={1}>
                  <Button
                    className='d-flex align-items-center'
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                    icon={<UploadOutlined />}
                  >
                    Upload
                  </Button>
                </Upload>
                <Button
                  className='d-flex align-items-center'
                  style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={handleExportCsv}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
                <Button
                  className='d-flex align-items-center'
                  type='primary'
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                  onClick={addOrderInventory}
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </div>
            </div>
            <ProductInventoryTable
              inventoryTableLoading={inventoryLoading}
              formLoading={formLoading}
              productData={inventories}
              showUserOrdersAction={true}
              handleTableUpdate={handleTableUpdate}
              handleInventoryDelete={handleInventoryDelete}
              handleInventoryRefresh={getInventory}
              isFormActive={adminOrderForms.isActive}
              selectedOrderFormId={selectedOrderFormId}
            />
          </div>

          <AddOrderInventory
            visible={orderInventoryPopupVisible}
            onCancel={handleCancel}
            onSave={handleSaveOrderInventory}
            onCancelForm={() => addOrderInventoryPopupRef}
            initialValues={orderTiersEdit}
            popupTitle={'Inventory'}
            loading={inventoryLoading}
          />
        </Card>
      )}
      {/* Inventory Table End*/}
      {toggleOrderDetailsCard && (
        <Collapse ghost onChange={togglePanelCollapse}>
          <Panel header={panelHeaderText} className='panel-header' key='1'>
            {toggleOrderDetailsCard && (
              <Card className='mb-3 filter-row' loading={formLoading}>
                <Form
                  form={form}
                  name='order'
                  layout='vertical'
                  initialValues={{ selectedWeek /*selectedYear*/ }}
                >
                  <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <div className='d-flex gap-2'>
                      <Form.Item name='allowHeated'>
                        <Switch
                          checkedChildren='Allow heat Packs'
                          defaultChecked={adminOrderForms?.allowHeated}
                          unCheckedChildren='No heat Packs'
                        />
                      </Form.Item>
                      <Form.Item name='zeroQuantity'>
                        <Switch
                          checkedChildren='Allow with zero quantity'
                          defaultChecked={adminOrderForms?.zeroQuantity}
                          unCheckedChildren='Validate item quantity'
                        />
                      </Form.Item>
                      <Form.Item name='isActive'>
                        <Switch
                          checkedChildren='Start Sale'
                          defaultChecked={adminOrderForms?.isActive}
                          unCheckedChildren='Stop Sale'
                        />
                      </Form.Item>
                      <Form.Item name='isUnderMaintenance'>
                        <Switch
                          checkedChildren='In Maintenance'
                          defaultChecked={adminOrderForms?.isUnderMaintenance}
                          unCheckedChildren='Not In Maintenance'
                        />
                      </Form.Item>
                    </div>
                    <div className='d-flex gap-2'>
                      <Button
                        className='d-flex align-items-center'
                        icon={<CopyOutlined />}
                        onClick={cloneForm}
                      >
                        Clone
                      </Button>
                      {!adminOrderForms.saleStarted && (
                        <Button
                          className='d-flex align-items-center'
                          type='primary'
                          danger
                          onClick={openDeletePopUp}
                        >
                          <DeleteOutlined />
                          Delete
                        </Button>
                      )}
                      <Modal
                        title='Delete Order Form'
                        open={deletePopUpVisible}
                        onOk={handleDeleteOk}
                        onCancel={handleDeleteCancel}
                        footer={[
                          <Button key='no' onClick={handleDeleteCancel}>
                            No
                          </Button>,
                          <Button
                            loading={deleteLoader}
                            key='yes'
                            danger
                            type='primary'
                            onClick={handleDeleteOk}
                          >
                            Yes
                          </Button>,
                        ]}
                      >
                        Are you sure you want to delete this order form?
                      </Modal>
                    </div>
                  </div>
                  <div
                    className='d-flex flex-wrap gap-2'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item
                        name='title'
                        label='Title'
                        rules={[
                          { required: true, message: 'Please enter title' },
                          { validator: validateWhiteSpace },
                        ]}
                      >
                        <Input placeholder='Title' />
                      </Form.Item>
                    </div>
                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item
                        name='minOrderAmt'
                        label='Min Order Amount'
                        rules={[
                          { required: true, message: 'Please enter Min order amount' },
                          { validator: handleNegativeValidator },
                        ]}
                      >
                        <Input
                          addonBefore={'$'}
                          placeholder='Min order amount'
                          min={0}
                          type='number'
                        />
                      </Form.Item>
                    </div>

                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item
                        label='Start Ship Week'
                        name='shippingWeek'
                        rules={[{ required: true, message: 'Please select start shipping week' }]}
                      >
                        <Select
                          disabled={adminOrderForms.isActive}
                          labelInValue
                          id='startWeek'
                          placeholder={'Select Start Ship Week'}
                          allowClear
                          onChange={handleStartWeekChange}
                          options={startShippingWeeks}
                          style={{ width: '200px' }}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item
                        label='End Ship Week'
                        name='endShippingWeek'
                        rules={[{ required: true, message: 'Please select end shipping week' }]}
                      >
                        <Select
                          disabled={adminOrderForms.isActive}
                          labelInValue
                          placeholder={'Select End Ship Week'}
                          id='endWeek'
                          options={endWeekOptions}
                          style={{ width: '200px' }}
                          value={endWeekValue}
                        ></Select>
                      </Form.Item>
                    </div>

                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item
                        label='Deposit'
                        name='deposit'
                        rules={[
                          { validator: handleNegativeValidator },
                          { validator: handleMax100 },
                        ]}
                      >
                        <Input addonAfter={'%'} placeholder='Deposit' min={0} type='number' />
                      </Form.Item>
                    </div>

                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item name='password' label='Password'>
                        <Input placeholder='Password' />
                      </Form.Item>
                    </div>

                    <div className='dropdown-container d-flex flex-column'>
                      <Form.Item name='year' label='Year'>
                        <Select
                          placeholder={'Select Year'}
                          disabled
                          allowClear
                          options={yearsList}
                          style={{ width: '200px' }}
                        ></Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className='d-flex gap-2'>
                    <div className='dropdown-container flex-grow-1'>
                      <Form.Item name='description' label='Description'>
                        <TextArea rows={3} placeholder='Enter Description' />
                      </Form.Item>
                    </div>
                    <div className='dropdown-container flex-grow-1' style={{ maxWidth: '50%' }}>
                      <Form.Item name='notes' label='Notes'>
                        <ReactQuill ref={quillRef} value={notesHtml} />
                      </Form.Item>
                    </div>
                  </div>
                </Form>
                <div className='d-flex justify-content-end align-center'>
                  <Button
                    onClick={handleOrderFormSubmit}
                    className='d-flex table-title-row'
                    type='primary'
                    icon={<SyncOutlined />}
                  >
                    Update
                  </Button>
                </div>
              </Card>
            )}

            {toggleOrderDetailsCard && (
              <Card className='mb-3 no-padding-card'>
                <Table
                  loading={formLoading}
                  dataSource={[adminOrderForms?.reports]}
                  columns={reportColumns}
                  pagination={false}
                  bordered
                  rowKey='totalOrdered'
                />
              </Card>
            )}

            {toggleOrderDetailsCard && (
              <Card className='mb-3'>
                <div className='discounts-section'>
                  {discounts && (
                    <div className='py-1'>
                      <Checkbox
                        onChange={onDiscountChange}
                        disabled={formLoading}
                        checked={discountAmountBased}
                      >
                        Discount for this order form amount($) based.
                      </Checkbox>
                    </div>
                  )}
                  <div className='d-flex gap-2'>
                    {/* Discounts Table */}
                    <div className='flex-grow-1'>
                      <div className='d-flex table-title-row'>
                        <h5>Discounts</h5>
                        <Button
                          className='d-flex align-items-center'
                          type='primary'
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                          onClick={addDiscounts}
                          icon={<PlusOutlined />}
                        >
                          Add Discount
                        </Button>
                      </div>

                      <Table
                        loading={discountsLoading}
                        bordered
                        columns={discountColumns}
                        dataSource={discounts}
                        pagination={false}
                        rowKey='id'
                      />
                    </div>

                    {/* Files Table */}
                    <div className='flex-grow-1'>
                      <div className='d-flex table-title-row'>
                        <h5>Files</h5>
                        <Button
                          type='primary'
                          className='d-flex align-items-center'
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                          onClick={addFiles}
                          icon={<PlusOutlined />}
                        >
                          Add File
                        </Button>
                      </div>

                      <Table
                        loading={filesLoading}
                        bordered
                        columns={filesColumns}
                        dataSource={files}
                        pagination={false}
                        rowKey='id'
                      />
                    </div>
                  </div>
                </div>

                <EditAddDiscounts
                  visible={discountPopupVisible}
                  onCancel={handleCancel}
                  onSave={handleSaveDiscount}
                  loading={discountsLoading}
                  onCancelForm={() => addEditDiscountPopupRef}
                  initialValues={discountEdit}
                  popupTitle={popupName}
                />

                <EditAddFiles
                  visible={filesPopupVisible}
                  onCancel={handleCancel}
                  onSave={handleSaveFiles}
                  loading={filesLoading}
                  onCancelForm={() => addEditFilesPopupRef}
                  initialValues={filesEdit}
                  popupTitle={popupName}
                />
              </Card>
            )}

            {toggleOrderDetailsCard && (
              <Card className='mb-3'>
                <div>
                  <div className='d-flex table-title-row'>
                    <div>
                      <h5>Order Tiers </h5>
                      <span>
                        (<strong>Note:</strong> If you wish to maintain the tier as closed, please
                        include 0.)
                      </span>
                    </div>
                    <Button
                      className='d-flex align-items-center'
                      type='primary'
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                      onClick={addOrderTiers}
                      icon={<PlusOutlined />}
                    >
                      Add Order Tier
                    </Button>
                  </div>

                  <Table
                    loading={tiersLoading}
                    bordered
                    columns={orderTiersColumns}
                    dataSource={tiers}
                    pagination={false}
                    rowKey='id'
                  />
                </div>

                <EditAddTiers
                  visible={orderTiersPopupVisible}
                  onCancel={handleCancel}
                  onSave={handleSaveOrderTiers}
                  loading={tiersLoading}
                  onCancelForm={() => addEditTiersPopupRef}
                  initialValues={orderTiersEdit}
                  popupTitle={'Tiers'}
                  sameTiers={sameTiers}
                  tierLength={tiers.length}
                />
              </Card>
            )}
          </Panel>
        </Collapse>
      )}
      <FloatButton.BackTop />
    </div>
  );
}

export default OrderInventory;
