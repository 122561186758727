import React, { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import MainContent from './layout/main-content';
import Profile from './pages/profile/profile';
import OrderForms from './pages/order-form/orderForm';
import UserSetting from './pages/user-setting/user-setting';
import GetCustomers from './pages/Admin/customers/getCustomers';
import CustomerOrderForm from './pages/Admin/customers/customerOrderForm';

import { Amplify, Hub } from 'aws-amplify';
import { Authenticator, PhoneNumberField } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { Autocomplete, TextField } from '@aws-amplify/ui-react';
import { province } from './common/province';
import Products from './pages/order-form/products';
import OrderForm from './pages/order-form/orderForm';
import api from './config/axios';
import { checkUser } from './config/api-routes';
import NotFound from './layout/notFound';
import { UserData } from './schema/user';
import { decryptString, encryptString } from './common/encryption';
import Spinner from './layout/spinner';
import ProtectedRoute from './common/ProtectedRoute';
import Unauthorized from './layout/unauthorized';
import Orders from './pages/Admin/orders';
import OrderInventory from './pages/Admin/orderInventory';
import AdminOrderForm from './pages/Admin/AdminOrderForm/addNewOrderForm';
import OrderDetails from './pages/Admin/orderDetails/orderDetails';
import PrintLabel from './pages/Admin/print/printLabel';
import Taxes from './pages/Admin/taxes';
import OrdersByName from './pages/Admin/ordersByName';
import { ConfigProvider, message } from 'antd';

import imageSrc from './assets/images/unicornblooms-img.png';
import signInNote from './assets/images/signin-note.jpg';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import UserOrders from './pages/Admin/Modals/userOrders';
import ReplaceOrder from './pages/Admin/ReplaceOrder/replaceOrder';
import Reports from './pages/Admin/Reports/reports';
I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
  'Sign in to your account': 'Welcome Back!',
  Username: 'Enter your username', // Username label
  Password: 'Enter your password', // Password label
  'Forgot your password?': 'Reset Password',
  'Create Account': 'Sign Up', // Tab header
  'Create a new account': 'New User', // Header text
  'Confirm Password': 'Confirm your password', // Confirm Password label
});

type ThemeData = {
  borderRadius: number;
  colorPrimary: string;
  Button?: {
    colorPrimary: string;
    colorHover: string;
    algorithm: string;
  };
  ButtonBase?: {
    colorHover: string;
  };
  Dropdown?: {
    color: string;
  };
};

const primaryColor = '#C92C6D';

const defaultData: ThemeData = {
  borderRadius: 10,
  colorPrimary: primaryColor,
  Button: {
    colorPrimary: primaryColor,
    colorHover: primaryColor,
    algorithm: primaryColor,
  },
  ButtonBase: {
    colorHover: primaryColor, // Hover color for all buttons
  },
  Dropdown: {
    color: primaryColor, // Color for dropdowns
  },
};

Amplify.configure(awsExports);
let checkUserFired = false;

export default function App(): JSX.Element {
  const [user, setUser] = React.useState<UserData>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loggedUserDataStr = localStorage.getItem('loggedUserData');
  const [themeData, setData] = React.useState(defaultData);

  useState(() => {
    if (loggedUserDataStr) {
      const parsedData = JSON.parse(decryptString(loggedUserDataStr));
      setUser(parsedData);
    }
  });

  useEffect(() => {
    if (loggedUserDataStr) {
      const parsedData = JSON.parse(decryptString(loggedUserDataStr));
      if (parsedData && parsedData.role !== 'User') {
        document.body.classList.add('customer-body-pd');
      } else {
        document.body.classList.remove('customer-body-pd');
      }
    }
  }, [loggedUserDataStr]);

  const addUserInfo = async () => {
    try {
      setLoading(true);
      let userData;
      try {
        userData = await api.post<never, any>(checkUser);
      } catch (error: any) {
        message.error(error?.response?.data?.message);
      }

      if (userData) {
        localStorage.setItem(
          'loggedUserData',
          encryptString(JSON.stringify({ role: userData.role })),
        );
      }

      setUser(userData);

      if (userData?.role == 'Admin') {
        navigate('/order-inventory');
      } else if (userData?.role == 'User') {
        navigate('/order-now');
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    ); // Display a loader while loading
  }

  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signIn':
        if (data.payload?.data?.attributes) {
          if (!checkUserFired) {
            checkUserFired = true;
            addUserInfo();
          }
        }
        break;
      case 'signUp':
        break;
      case 'signOut':
        checkUserFired = false;
        localStorage.removeItem('loggedUserData');
        break;
      case 'signIn_failure':
        break;
      case 'configured':
    }
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeData.colorPrimary,
          borderRadius: themeData.borderRadius,
        },
        components: {
          Button: {
            colorPrimary: themeData.Button?.colorPrimary,
          },
        },
      }}
    >
      <Authenticator
        initialState='signIn'
        // Customize `Authenticator.SignUp.FormFields`
        components={{
          SignUp: {
            FormFields() {
              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />
                  <TextField isRequired label='Contact Name' name='custom:customerName' />
                  <PhoneNumberField
                    defaultDialCode='+1'
                    isRequired
                    dialCodeList={['+1']}
                    label='Phone number'
                    name='custom:phoneNumber'
                  />
                  <TextField
                    isRequired
                    placeholder='Re-enter Contact Name if you do not have a Business Name.'
                    label='Business Name'
                    name='custom:businessName'
                  />
                  <b className='mt-3'>Shipping Address</b>
                  <hr className='m-0' />
                  <TextField isRequired label='Street Address' name='custom:streetAddress' />
                  <TextField
                    placeholder='ex. Unit # or PO Box (leave blank if none)'
                    label='Address Line 2'
                    name='custom:addressLine2'
                  />
                  <TextField isRequired label='City' name='custom:city' />
                  <TextField required label='Postal Code' name='custom:postalCode' />
                  Province
                  <Autocomplete
                    isRequired
                    label='Province'
                    name='custom:province'
                    options={province}
                  />
                  <TextField
                    descriptiveText='PST number (if applicable)'
                    label='Tax'
                    name='custom:taxNo'
                  />
                </>
              );
            },
          },
          Header() {
            return (
              <>
                <div className='d-flex align-items-center justify-content-center mb-3'>
                  <img
                    width={'240px'}
                    height={'240px'}
                    style={{ borderRadius: '5px' }}
                    src={imageSrc}
                  />
                </div>
              </>
            );
          },
        }}
      >
        <main>
          <Routes>
            <Route path='/' element={<MainContent />}>
              <Route element={<UserSetting />}></Route>

              {!!user && user.role === 'User' && (
                <Route index element={<Navigate replace to='/order-now' />}></Route>
              )}

              {!!user && user.role === 'Admin' && (
                <Route index element={<Navigate replace to='/order-inventory' />}></Route>
              )}

              <Route
                path='/order-now'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'User'}
                  >
                    <Products />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/personal-info'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'User'}
                  >
                    <Profile />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/order-form'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'User'}
                  >
                    <OrderForms />
                  </ProtectedRoute>
                }
              ></Route>

              {/* <Route path='/customers' element={<GetCustomers />}></Route> */}

              <Route
                path='/customers'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <GetCustomers />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/taxes'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <Taxes />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/reports'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <Reports />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/order-inventory'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <OrderInventory />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/replace-order'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <ReplaceOrder />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/user-orders/:orderId'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <OrdersByName />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/print'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <PrintLabel />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/new-order-form/:productId?/:distinctYear?/:orderFormId?'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <AdminOrderForm />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/orders'
                element={
                  <ProtectedRoute
                    redirectPath='/unauthorized'
                    isAllowed={!!user && user.role == 'Admin'}
                  >
                    <Orders />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/order/:id'
                element={
                  <ProtectedRoute isAllowed={!!user && user.role == 'Admin'}>
                    <OrderDetails />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/customerOrderForm/:customerId'
                element={
                  <ProtectedRoute isAllowed={!!user && user.role == 'Admin'}>
                    <CustomerOrderForm />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path='/product/:id/:customerId'
                element={
                  <ProtectedRoute isAllowed={!!user && user.role == 'Admin'}>
                    <OrderForm />
                  </ProtectedRoute>
                }
              ></Route>

              <Route path='/user-setting' element={<UserSetting />}></Route>
              <Route path='/product/:id' element={<OrderForm />}></Route>
              <Route path='*' Component={NotFound} />
              <Route path='/unauthorized' element={<Unauthorized />}></Route>
            </Route>
          </Routes>
        </main>
      </Authenticator>
    </ConfigProvider>
  );
}
