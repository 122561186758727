import React from 'react';
import moment from 'moment';
import { Product } from '../../../schema/order';

type PackingReportProps = {
  dataSource: Product[];
  shippingWeek: number;
};

const PackingReport = ({ dataSource, shippingWeek }: PackingReportProps) => {
  interface WeekData {
    weekNumber: number;
    startDate: Date;
    endDate: Date;
  }
  const getWeekDates = (weekNumber: number): WeekData => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const currentYear = date.getFullYear();

    const firstDayOfYear = new Date(currentYear, 0, 1);

    const dayOfWeek = firstDayOfYear.getDay();
    const daysToAdd = (weekNumber - 1) * 7 - dayOfWeek + 1;

    const startDate = new Date(currentYear, 0, 1 + daysToAdd);
    const endDate = new Date(currentYear, 0, 1 + daysToAdd + 6);

    return {
      weekNumber,
      startDate,
      endDate,
    };
  };
  const shipping: any = shippingWeek && getWeekDates(shippingWeek);
  return (
    <body>
      <br />

      <div>
        <br></br>
        <div>
          <div>
            <h3 className='title-grey'>
              Packing Report for : Ship Week {shippingWeek} (
              {moment(shipping.startDate).format('LL')} - {moment(shipping.endDate).format('LL')})
            </h3>
          </div>
          <table>
            <thead>
              <tr>
                <th>Variety</th>
                <th>Type</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Breakdown</th>
              </tr>
            </thead>
            <tbody>
              {dataSource.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.size}</td>
                  <td>{item.quantity}</td>
                  <td>{item.breakdown}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </body>
  );
};

export default PackingReport;
