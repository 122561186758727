import React from 'react';

import Header from './header';
import SideBar from './sidebar';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '../context/theme-context';
import { StepperProvider } from '../context/stepper-context';

function MainContent() {
  return (
    <>
      <ThemeProvider>
        <StepperProvider>
          <Header></Header>
          <SideBar></SideBar>
          <div>
            <Outlet />
          </div>
        </StepperProvider>
      </ThemeProvider>
    </>
  );
}

export default MainContent;
