import { Button, Card, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import api from '../../config/axios';
import { changePassword } from '../../config/api-routes';
import { Auth } from 'aws-amplify';
import Spinner from '../../layout/spinner';
import Title from 'antd/es/typography/Title';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 10,
    },
  },
};

const validatePassword = (_: any, value: string) => {
  if (!value) {
    return Promise.reject('Please enter a password');
  }
  if (value.length < 8) {
    return Promise.reject('Password must be at least 8 characters long');
  }
  if (!/[A-Z]/.test(value)) {
    return Promise.reject('Password must contain at least one uppercase letter');
  }
  if (!/[^a-zA-Z0-9]/.test(value)) {
    return Promise.reject('Password must contain at least one special character');
  }
  return Promise.resolve();
};

const UserSetting: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // Handle error signing out
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    await api
      .put(changePassword, values)
      .then(() => {
        message.success('Password changed successfully!');
        setLoading(false);
        handleSignOut();
      })
      .catch((e) => {
        message.error(e?.response?.data?.message);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    ); // Display a loader while loading
  }

  return (
    <div
      className='pt-4 d-flex justify-content-center align-items-center'
      style={{ height: '80vh' }}
    >
      <Card
        hoverable
        bordered
        title={<Title level={3}>Change Password</Title>}
        style={{ width: 700 }}
      >
        <Form
          {...formItemLayout}
          form={form}
          name='passwordChange'
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
          scrollToFirstError
        >
          <Form.Item
            name='previousPassword'
            label='Old Password'
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
            />
          </Form.Item>

          <Form.Item
            name='proposedPassword'
            label='New Password'
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
            />
          </Form.Item>

          <Form.Item
            name='confirm'
            label='Confirm Password'
            dependencies={['proposedPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('proposedPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!'),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              iconRender={(visible) => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type='primary' htmlType='submit'>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default UserSetting;
