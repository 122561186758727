/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ca-central-1',
  aws_cognito_region: 'ca-central-1',
  // aws_user_pools_id: 'ca-central-1_FtEogP3th',
  // aws_user_pools_web_client_id: '6p5thfll7thcbs4fn0no5hrm1b',
  // oauth: {
  //   domain: 'uniconblooms-qa.auth.ca-central-1.amazoncognito.com',
  // },
  aws_user_pools_id: 'ca-central-1_5DoSzQmvU',
  aws_user_pools_web_client_id: '5d0ugcak0ffrifu2j3qmb4dj1g',
  oauth: {
    domain: 'unicornblooms-qa.auth.ca-central-1.amazoncognito.com',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
   passwordPolicyMinLength: 8,
   passwordPolicyCharacters: [
     'REQUIRES_LOWERCASE',
     'REQUIRES_UPPERCASE',
     'REQUIRES_NUMBERS',
     'REQUIRES_SYMBOLS',
   ],
 },
 aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsmobile;