import React, { useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import { Product } from '../../schema/order';
import { inventory, replaceOrder } from '../../config/api-routes';
import api from '../../config/axios';
import ReplaceSomeCustomerOrderTable from './replaceSomeCustomerOrderTable';
import { useNavigate } from 'react-router-dom';

function ReplaceOrderTable({
  recordId,
  selectedOrderFormId,
  userRecord,
  closeModal,
  visibleReplaceOrderTable,
  visibleReplaceSomeOrderTable,
  onUpdate,
}: any) {
  const [dataSource, setDataSource] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Product[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (Number(recordId) > 0) {
      fetchInventoryByOrderFormId();
    }
  }, [recordId, visibleReplaceOrderTable]);

  const fetchInventoryByOrderFormId = async () => {
    setLoading(true);
    await api
      .get(`${inventory}/${selectedOrderFormId}`)
      .then((result: any) => {
        setDataSource(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Total Ordered',
      dataIndex: 'totalOrdered',
      render: (text: any) => (text ? <span>{text}</span> : <span>-</span>),
    },
    {
      title: 'Remaining Stock',
      dataIndex: 'totalStock',
    },
    {
      title: 'Total Stock',
      dataIndex: 'inventoryStock',
    },
    {
      title: 'Total Buffer',
      dataIndex: 'inventoryBuffer',
    },
    {
      title: 'Remaining Buffer',
      dataIndex: 'bufferStock',
    },
    {
      title: 'Size',
      dataIndex: 'size',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Sub Type',
      dataIndex: 'subType',
    },
    {
      title: 'Tier 1 Price',
      dataIndex: 'tier1Price',
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
    },
    {
      title: 'Tier 2 Price',
      dataIndex: 'tier2Price',
      render: (text: number) =>  <span>{Number(text).toFixed(2) === '0.00' ? '-' : `$${Number(text).toFixed(2)}`}</span>,
    },
    {
      title: 'Tier 3 Price',
      dataIndex: 'tier3Price',
      render: (text: number) =>  <span>{Number(text).toFixed(2) === '0.00' ? '-' : `$${Number(text).toFixed(2)}`}</span>,
    },
    {
      title: 'Image Url',
      dataIndex: 'imageUrl',
      width: 150,
      render: (_: any, record: any) => (
        <a
          href={
            record?.imageUrl?.indexOf('http') > -1 ? record.imageUrl : `https://${record.imageUrl}`
          }
          target='_blank'
          rel='noreferrer'
          className='word-break'
        >
          {record.imageUrl}
        </a>
      ),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      editable: true,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Product[]) => {
      setSelectedRow(selectedRows);
      onUpdate(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.id === recordId, // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleReplaceAll = () => {
    setLoading(true);
    const payload = {
      changedId: recordId,
      replaceId: selectedRow[0].id,
    };

    api
      .put(`${replaceOrder}`, payload)
      .then((res: any) => {
        setLoading(false);
        message.success(res?.message);
        navigate('/order-inventory');
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <div className='px-2 pt-2'>
      {visibleReplaceOrderTable && (
        <div>
          <div
            className='d-flex align-items-center pb-2'
            style={{ justifyContent: 'space-between' }}
          >
            <div className='d-flex gap-4'>
              <div className='d-flex gap-2'></div>
            </div>
            <div className='align-self-center'>
              <Button
                onClick={handleReplaceAll}
                disabled={!selectedRow.length}
                className='d-flex table-title-row'
                type='primary'
              >
                Replace
              </Button>
            </div>
          </div>
          <Table
            scroll={{ y: '60vh' }}
            className='pt-2'
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            loading={loading}
            rowClassName={() => 'editable-row'}
            rowKey='id'
            bordered
            pagination={false}
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      )}
      {visibleReplaceSomeOrderTable && (
        <ReplaceSomeCustomerOrderTable selectedProduct={selectedRow} recordId={recordId} />
      )}
    </div>
  );
}

export default ReplaceOrderTable;
