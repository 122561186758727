import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button } from 'antd';

interface AddOrderInventoryProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (item: any) => void;
  onCancelForm: () => void; // New prop to handle form reset
  initialValues?: any;
  popupTitle: string;
  loading?: boolean;
}

const AddOrderInventory: React.FC<AddOrderInventoryProps> = ({
  visible,
  onCancel,
  onSave,
  initialValues,
  popupTitle,
  loading,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef<HTMLFormElement | any>(null);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [initialValues, visible]);

  const handleSave = () => {
    form
      .validateFields()
      .then((values: any) => {
        initialValues ? onSave({ ...values, id: initialValues.id }) : onSave(values);
      })
      .catch((error: any) => {
        return false;
      });
  };

  const validateGreaterThanZero = (_: any, value: number) => {
    if (value > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Value must be greater than 0');
  };
  const handleNegativeValidator = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value < 0) {
        reject('Negative values are not allowed');
      } else {
        resolve();
      }
    });
  };

  const validateWhiteSpace = (_: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Input cannot consist only of spaces');
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={visible}
      title={`Add ${popupTitle}`}
      onCancel={onCancel}
      onOk={handleSave}
      destroyOnClose
      footer={[
        <Button key='back' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={handleSave}>
          Submit
        </Button>,
      ]}
    >
      <Form form={form} ref={formRef} name='orderInventoryForm' layout='vertical'>
        <Form.Item
          name='name'
          label='Name'
          rules={[
            { required: true, message: 'Please enter name' },
            { validator: validateWhiteSpace },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='size'
          label='Size'
          rules={[
            { required: true, message: 'Please enter size' },
            { validator: validateWhiteSpace },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='type'
          label='Type'
          rules={[
            { required: true, message: 'Please enter type' },
            { validator: validateWhiteSpace },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name='subType' label='Sub Type' rules={[{ validator: validateWhiteSpace }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name='tier1Price'
          label='Tier 1 Price'
          initialValue={0}
          rules={[
            { required: true, message: 'Please enter Tier 1 Price' },
            { validator: handleNegativeValidator },
          ]}
        >
          <Input addonBefore={'$'} type='number' />
        </Form.Item>
        <Form.Item
          name='tier2Price'
          label='Tier 2 Price'
          initialValue={0}
          rules={[
            { required: true, message: 'Please enter Tier 2 Price' },
            { validator: handleNegativeValidator },
          ]}
        >
          <Input addonBefore={'$'} type='number' />
        </Form.Item>
        <Form.Item
          name='tier3Price'
          label='Tier 3 Price'
          initialValue={0}
          rules={[
            { required: true, message: 'Please enter Tier 3 Price' },
            { validator: handleNegativeValidator },
          ]}
        >
          <Input addonBefore={'$'} type='number' />
        </Form.Item>

        <Form.Item
          name='totalStock'
          label='Total Stock'
          initialValue={0}
          rules={[
            { required: true, message: 'Please enter total stock' },
            {
              validator: validateGreaterThanZero,
            },
            { validator: handleNegativeValidator },
          ]}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item name='imageUrl' label='Image URL' rules={[{ validator: validateWhiteSpace }]}>
          <Input />
        </Form.Item>

        <Form.Item name='notes' label='Notes' rules={[{ validator: validateWhiteSpace }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOrderInventory;
