import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Table, Tag, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { debounce } from 'lodash';
import { AdminOrders } from '../../schema/order';
import {
  PLPrintedStatus,
  packedStatus,
  shipLabel,
  shippedStatus,
  invoiceStatus,
  paymentStatus,
} from '../../common/orderFilters';
import { orderList, getAllProductLines, bulkReminder } from '../../config/api-routes';
import api from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import { HistoryOutlined } from '@ant-design/icons';

const { Search } = Input;

function OrderTable() {
  const [productTypes, setProductTypes] = useState([]);
  const [order, setOrderData] = useState<AdminOrders[]>([]);
  const [loading, setLoading] = useState(false);
  const [shippingWeeks, setShippingWeeks] = useState([]);
  const [visible, setVisible] = useState<boolean>();
  const [form] = Form.useForm();
  const [selectedShipWeek, setSelectedShipWeek] = useState<number>(0);
  const [selectedProductType, setSelectedProductType] = useState<string>('');
  const [selectedPayment, setSelectedPayment] = useState<number>(0);
  const [selectedShipped, setSelectedShipped] = useState<number>(0);
  const [loader, setLoaer] = useState<boolean>();

  //const [selectedFilters, setSelectedFilters] = useState({});

  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string }>(() => {
    //const storedFilters = localStorage.getItem('selectedFilters');
    const queryParams: any = {};
    const queryString = window.location.search.substring(1);

    queryString.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key && value) {
        queryParams[key] = key == 'searchText' ? value : Number(decodeURIComponent(value));
      }
    });
    return queryParams ? queryParams : {};
  });

  const navigate = useNavigate();

  const filterData = [
    { label: 'Ship Week', type: 'shipweek', options: shippingWeeks, value: '' },
    { label: 'Product Type', type: 'productLine', options: productTypes, value: '' },
    { label: 'PL Printed', type: 'plPrinted', options: PLPrintedStatus, value: '' },
    { label: 'Packed', type: 'packed', options: packedStatus, value: '' },
    { label: 'Ship Label', type: 'shipLabel', options: shipLabel, value: '' },
    { label: 'Shipped', type: 'shipped', options: shippedStatus, value: '' },
    { label: 'Invoiced', type: 'isInvoiced', options: invoiceStatus, value: '' },
    { label: 'Order Status', type: 'payment', options: paymentStatus, value: '' },
  ];

  const columns: ColumnsType<AdminOrders> = [
    {
      title: 'Order No',
      dataIndex: 'orderNumber',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Ship Week',
      dataIndex: 'shipweek',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      width: '15%',
      align: 'center',
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Payment Reminder',
      dataIndex: 'paymentReminder',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Order Status',
      dataIndex: 'paymentStatus',
      align: 'center',
      render: (value) => renderSwitch(value),
    },
  ];

  useEffect(() => {
    getShippingWeeks();
    getAllProductTypes();
    if (!selectedFilters) {
      getOrdersList();
    }
  }, []);

  const renderSwitch = (paymentStatus: string) => {
    switch (paymentStatus) {
      case 'CANCELLED':
        return (
          <span>
            {
              <Tag color='#42434b' style={{ color: 'white' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'UNPAID':
        return <span>{<Tag color='#BA704F'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PAID':
        return <span>{<Tag color='#7A9D54'>{paymentStatus}</Tag>}</span>;
        break;
      case 'OVERPAID':
        return <span>{<Tag color='#75C2F6'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PARTIAL':
        return (
          <span>
            {
              <Tag color='#FDFFAE' style={{ color: 'black' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'DEPOSIT PAID':
        return <span>{<Tag color='gold'>{paymentStatus}</Tag>}</span>;
        break;
      case 'NONE':
        return <span>{<Tag color='green'>{paymentStatus}</Tag>}</span>;
        break;
      default:
        return <span></span>;
    }
  };

  const getShippingWeeks = () => {
    const weeksData = [];
    for (let i = 1; i <= 50; i++) {
      weeksData.push({ id: i, value: `Week ${i}` });
    }
    const shipWeeks = JSON.parse(JSON.stringify(weeksData, null, 2));
    setShippingWeeks(shipWeeks);
  };

  const getAllProductTypes = async () => {
    setLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const getOrdersList = async () => {
    setLoading(true);
    await api
      .post<never, AdminOrders[]>(orderList, order)
      .then((result: AdminOrders[]) => {
        setOrderData(result);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const getOrdersWithFilters = async (payload: any) => {
    setLoading(true);
    await api
      .post<never, AdminOrders[]>(orderList, payload)
      .then((result: AdminOrders[]) => {
        setOrderData(result);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleInputChange = (event: any) => {
    if (event) {
      if (event.target.value != '') {
        addFilterToQueryParams('searchText', event.target.value);
      } else {
        removeFilterFromQueryParams('searchText');
      }

      setSelectedFilters((prevFilters: any) => ({
        ...prevFilters,
        ['searchText']: event.target.value,
      }));
    }
  };

  useEffect(() => {
    const queryParams: any = {};
    const queryString = window.location.search.substring(1);

    queryString.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key && value) {
        queryParams[key] = decodeURIComponent(value);
      }
    });
    getOrdersWithFilters(queryParams);
  }, [location.search]);

  const addFilterToQueryParams = (type: any, value: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(type, value);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  const removeFilterFromQueryParams = (type: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(type);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  // useEffect(() => {
  //   localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
  //   getOrdersWithFilters(selectedFilters);
  // }, [selectedFilters]);

  const handleFilterChange = (type: any, event: any) => {
    const value = event ? Number(event.value) : undefined;

    // Add or remove the filter from query parameters based on the filter type
    if (value !== undefined) {
      addFilterToQueryParams(type, value);
    } else {
      removeFilterFromQueryParams(type);
    }

    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [type]: event ? Number(event.value) : undefined,
    }));
  };

  const debouncedHandleInputChange = debounce(handleInputChange, 1000);

  const rowProps = (record: any) => {
    return {
      onClick: () => navigate(`/order/${record.id}`),
    };
  };

  const handleBulkReminder = () => {
    setVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const options: any = Array.from({ length: 50 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));

  const handleShipWeekChange = (value: number) => {
    setSelectedShipWeek(value);
  };

  const handleProductTypeChange = (value: string) => {
    setSelectedProductType(value);
  };

  const handlePayment = (value: number) => {
    setSelectedPayment(value);
  };

  const handleShipped = (value: number) => {
    setSelectedShipped(value);
  };

  const sendReminder = () => {
    setLoaer(true);
    form
      .validateFields()
      .then((values: any) => {
        api
          .put(bulkReminder, values)
          .then((res: any) => {
            message.success(res.message);
            setLoaer(false);
            form.resetFields();
          })
          .catch((error) => {
            message.error(error.data.message);
            setLoaer(false);
          });
      })
      .catch((error: any) => {
        setLoaer(false);
      });
  };

  return (
    <div className='order-main px-2'>
      <h3 className='title-grey'>Orders</h3>
      <Card className='mb-4 filter-row'>
        <span className='search-row d-flex justify-content-center p-2'>
          <Search
            placeholder='Search by Contact name / Business name'
            onChange={debouncedHandleInputChange}
            allowClear
            defaultValue={selectedFilters['searchText']}
            size='large'
            style={{ width: 600 }}
          />
        </span>
        <span className='search-row d-flex justify-content-end p-2'>
          <Button
            className='d-none'
            type='primary'
            icon={<HistoryOutlined />}
            onClick={handleBulkReminder}
          >
            Bulk Reminder
          </Button>
          <Modal
            open={visible}
            title={'Send a bulk Reminder'}
            onCancel={onCancel}
            onOk={sendReminder}
            destroyOnClose
            footer={[
              <Button key='no' onClick={onCancel}>
                Cancel
              </Button>,
              <Button key='ok' type='primary' onClick={sendReminder} loading={loader}>
                Send Reminder
              </Button>,
            ]}
          >
            <Form form={form} layout='vertical' onFinish={sendReminder}>
              <div className='d-flex justify-content-center gap-4'>
                <Form.Item label='Ship Week' name='shipweek' className='w-100'>
                  <Select
                    placeholder='Select Ship Week'
                    onChange={handleShipWeekChange}
                    value={selectedShipWeek}
                  >
                    {options.map((options: any) => (
                      <Select.Option key={options.value} value={options.value}>
                        {options.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label='Product Type' name='productLine' className='w-100'>
                  <Select
                    placeholder='Select Product Type'
                    onChange={handleProductTypeChange}
                    value={selectedProductType}
                  >
                    {productTypes.map((options: any) => (
                      <Select.Option key={options.value} value={options.value}>
                        {options.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='d-flex justify-content-center gap-4'>
                <Form.Item label='Shipped' name='shipped' className='w-100'>
                  <Select
                    placeholder='Select Shipped Status'
                    value={selectedShipped}
                    onChange={handleShipped}
                  >
                    <Select.Option value={1}>Not Shipped</Select.Option>
                    <Select.Option value={2}>Shipped</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Please Select Payment Type' }]}
                  label='Payment'
                  name='payment'
                  className='w-100'
                >
                  <Select
                    placeholder='Select Payment Type'
                    value={selectedPayment}
                    onChange={handlePayment}
                  >
                    <Select.Option value={1}>Deposit Reminder</Select.Option>
                    <Select.Option value={2}>Payment Reminder</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </span>

        <hr className='m-2' />
        <div className='d-flex flex-wrap gap-2 py-2' style={{ justifyContent: 'space-between' }}>
          {filterData.map((item, index) => (
            <div key={index} className='dropdown-container d-flex flex-column fw-600'>
              <span className='pb-2'>
                <label>{item.label}</label>
              </span>
              <Select
                placeholder={'Select ' + item.label}
                allowClear
                //options={item.options}
                options={item.options.map((option) => ({ label: option.value, value: option.id }))}
                style={{ width: '200px' }}
                value={selectedFilters[item.type]}
                onChange={(value, event) => handleFilterChange(item.type, event)}
              ></Select>
            </div>
          ))}
        </div>
        <hr />
        <Table
          bordered
          columns={columns}
          loading={loading}
          dataSource={order}
          rowKey='id'
          onRow={rowProps}
          pagination={{ pageSize: 100, showSizeChanger: false }}
        />
      </Card>
    </div>
  );
}

export default OrderTable;
