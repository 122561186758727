import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Select,
  Table,
  Tooltip,
  message,
} from 'antd';
import api from '../../../config/axios';
import { payment } from '../../../config/api-routes';
import { Payment } from '../../../schema/order';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import locale from 'antd/locale/en_US';
import { RangePickerProps } from 'antd/es/date-picker';

type OrderDetailsPaymentProps = {
  id: number;
  getOrderDetails: () => void;
  loading: boolean;
  isCancelled: boolean | undefined;
  amountPaid: number | undefined;
  remainingAmount: number | undefined;
  showReminderButton: boolean | undefined;
  sendReminderEmail: () => void;
  sendReminderLoader: boolean | undefined;
  invoiceDetails: any;
};

function OrderDetailsPayment({
  id,
  getOrderDetails,
  loading,
  isCancelled,
  amountPaid,
  remainingAmount,
  showReminderButton,
  sendReminderEmail,
  sendReminderLoader,
  invoiceDetails,
}: OrderDetailsPaymentProps) {
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [editingKey, setEditingKey] = useState<number | undefined>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [deletePaymentVisible, setDeletePaymentVisible] = useState<boolean>(false);
  const [deletePaymentId, setDeletePaymentId] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<number>(0);
  const [recordDate, setRecordDate] = useState<any>();
  const [newDate, setNewDate] = useState<string>('');
  const [isCreditRevert, setIsCreditRevert] = useState<string>('');
  const [isEditCreditRevert, setEditIsCreditRevert] = useState<string>('');

  const isEditing = (record: Payment) => record.id === editingKey;

  const edit = (record: Payment) => {
    const { amount, dateOfPayment, paymentMethod, paymentType } = record;
    const momentDate = moment(dateOfPayment);

    setRecordId(record.id);
    setRecordDate(moment(record.dateOfPayment).format('L'));

    const formObj = {
      id: id,
      amount: amount,
      dateOfPayment: momentDate,
      paymentMethod: paymentMethod,
      paymentType: paymentType,
    };

    setVisible(true);
    editForm.setFieldsValue(formObj);
  };

  const onDeletePaymentCancel = () => {
    setDeletePaymentVisible(false);
  };

  const onDeletePayment = () => {
    setLoader(true);
    api
      .delete(`${payment}/${deletePaymentId}`)
      .then((res: any) => {
        setDeletePaymentVisible(false);
        getOrderDetails();
        getPayments();
        setLoader(false);
        message.success(res?.message);
      })
      .catch((error) => {
        setLoader(false);
        setDeletePaymentVisible(false);
        message.error(error?.response?.data?.message);
      });
  };

  const deletePayment = (record: Payment) => {
    setDeletePaymentId(record.id);
    setDeletePaymentVisible(true);
  };

  useEffect(() => {
    if (id && id > 0) {
      getPayments();
    }
  }, [id]);

  const getPayments = async () => {
    setLoader(true);
    await api
      .get<never, Payment[]>(payment + '/' + id)
      .then((res: Payment[]) => {
        res.forEach(function (val, index) {
          val.paymentType === '' ? (val.paymentType = '-') : val.paymentType;
        });
        setPayments(res);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoader(false);
    getOrderDetails();
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'dateOfPayment',
      render: (text: any) => <span>{moment(text).format('LL')}</span>,
      key: 'date',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'method',
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text: number) => <span>${Number(text).toFixed(2)}</span>,
      align: 'right' as AlignType,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      align: 'center' as AlignType,
      render: (_: any, record: Payment) => {
        const editable = isEditing(record);
        return editable ? (
          <span className='d-flex gap-2 justify-content-center'></span>
        ) : (
          <div className='d-flex gap-2 justify-content-center'>
            <Tooltip title='Edit'>
              <EditOutlined disabled={isCancelled} onClick={() => edit(record)} />
            </Tooltip>
            <Tooltip title='Delete'>
              <DeleteOutlined disabled={isCancelled} onClick={() => deletePayment(record)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const addPayment = () => {
    form
      .validateFields()
      .then((values: any) => {
        setLoader(true);
        api
          .post<never, any>(payment + `/${id}`, values)
          .then((res: Payment) => {
            if (res) {
              form.resetFields();
              getPayments();
              getOrderDetails();
            }
            setIsCreditRevert('');
          })
          .catch((error) => {
            console.log(error);
            message.error(error?.response?.data?.message);
            setLoader(false);
          });
      })

      .catch((error: any) => {
        console.log(error);
        setLoader(false);
      });
  };

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: Payment) => ({
        record,
        title: col.title,
      }),
    };
  });

  const handleNegativeValidator = (rules: any, value: any) => {
    return new Promise<void>((resolve, reject) => {
      if (value != null && value <= 0) {
        reject('Value must be greater than 0.');
      } else {
        resolve();
      }
    });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const handleSave = () => {
    editForm
      .validateFields()
      .then((values: any) => {
        values = { ...values, dateOfPayment: dayjs(newDate) };
        setLoader(true);
        if (recordId !== 0) {
          api
            .put<never, any>(payment + `/${recordId}`, values)
            .then((res: Payment) => {
              if (res) {
                message.success('Payment updated successfully');
                setLoader(false);
                getPayments();
                getOrderDetails();
                setVisible(false);
                setEditIsCreditRevert('');
              }
            })
            .catch((error: any) => {
              message.error(error.response?.data?.message);
              setLoader(false);
              setVisible(false);
            });
        }
      })
      .catch(() => {
        return false;
      });
  };

  const setDate = (dateString: any) => {
    setNewDate(dateString);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    // Can not select days after today and today
    return current && current > dayjs().endOf('day');
  };

  return (
    <div>
      <div className='p-3'>
        <span className='secondary-color'>
          <b>Payments</b>
        </span>
        <Card loading={loader}>
          <div className='d-flex'>
            <div className='d-flex flex-grow-1 gap-5 pb-2'>
              {amountPaid != undefined && amountPaid > 0 && amountPaid && (
                <div>
                  <span className='secondary-color'>
                    <b>Total Paid</b>
                  </span>
                  <div>${amountPaid.toFixed(2)}</div>
                </div>
              )}

              <div>
                <span className='secondary-color'>
                  {remainingAmount != undefined && remainingAmount > 0 && <b>Total remaining</b>}
                  {remainingAmount != undefined && remainingAmount < 0 && <b>Total Overpaid</b>}
                </span>
                <div>
                  {remainingAmount != undefined && remainingAmount > 0 && (
                    <div>${remainingAmount?.toFixed(2)}</div>
                  )}
                  {remainingAmount != undefined && remainingAmount < 0 && (
                    <div>${(remainingAmount * -1)?.toFixed(2)}</div>
                  )}
                </div>
              </div>

              <div>
                <div className='secondary-color'>
                  {invoiceDetails && invoiceDetails.depositAmount > 0 && <b>Total Deposit</b>}
                </div>
                <div>
                  {invoiceDetails && invoiceDetails.depositAmount > 0 && (
                    <div>${invoiceDetails.depositAmount.toFixed(2)}</div>
                  )}
                </div>
              </div>

              <div>
                <div className='secondary-color'>
                  {invoiceDetails && invoiceDetails.depositAmount > 0 && <b>Remaining Deposit</b>}
                </div>
                <div>
                  {invoiceDetails && invoiceDetails.depositAmount > 0 && (
                    <div>${invoiceDetails.dueDepositAmount.toFixed(2)}</div>
                  )}
                </div>
              </div>
            </div>

            <div className='pt-2'>
              {showReminderButton && !isCancelled && (
                <div>
                  <Button
                    type='primary'
                    onClick={sendReminderEmail}
                    loading={loading || sendReminderLoader}
                  >
                    Send Reminder
                  </Button>
                </div>
              )}
            </div>
          </div>

          {!isCancelled && !loading && (
            <div className='payment-card'>
              <hr />
              <Form form={form} layout='vertical' className='payment-form'>
                <Form.Item
                  label='Amount'
                  name='amount'
                  className='payment-form-child'
                  rules={[
                    { required: true, message: 'Please enter the amount.' },
                    { validator: handleNegativeValidator },
                  ]}
                >
                  <InputNumber
                    className='w-100'
                    precision={2}
                    type='number'
                    placeholder='Enter amount'
                    addonBefore='$'
                  />
                </Form.Item>
                <Form.Item
                  label='Payment Method'
                  name='paymentMethod'
                  className='payment-form-child'
                  rules={[{ required: true, message: 'Please select a payment method' }]}
                >
                  <Select
                    placeholder='Select payment method'
                    loading={loader}
                    onChange={(value) => {
                      setIsCreditRevert(value);
                    }}
                  >
                    <Select.Option value='PayPal'>PayPal</Select.Option>
                    <Select.Option value='E-Transfer'>E-Transfer</Select.Option>
                    <Select.Option value='CreditCard'>Credit Card</Select.Option>
                    <Select.Option value='CreditRevert'>Credit Revert</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label='Payment Type'
                  name='paymentType'
                  className='payment-form-child'
                  rules={[
                    {
                      required: isCreditRevert !== 'CreditRevert',
                      message: 'Please select a Payment Type',
                    },
                  ]}
                >
                  <Select
                    placeholder='Select Payment Type'
                    loading={loader}
                    disabled={isCreditRevert === 'CreditRevert'}
                  >
                    <Select.Option value='Deposit'>Deposit</Select.Option>
                    <Select.Option value='Amount'>Amount</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  className='payment-form-child'
                  name='dateOfPayment'
                  rules={[{ required: true, message: 'Please select Date of Payment' }]}
                  label='Date of Payment'
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    className='w-100'
                    format={'YYYY/MM/DD'}
                    placeholder='Select Date of Payment'
                  />
                </Form.Item>
                <Form.Item className='payment-form-child button-align'>
                  <Button
                    type='primary'
                    loading={loading || loader}
                    className='payment-form-child d-flex align-items-center justify-content-center'
                    onClick={addPayment}
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>
              </Form>
              <Modal
                open={deletePaymentVisible}
                title={'Are you sure, you want to delete this payment?'}
                onCancel={onDeletePaymentCancel}
                onOk={onDeletePayment}
                destroyOnClose
                footer={[
                  <Button key='no' onClick={onDeletePaymentCancel}>
                    No
                  </Button>,
                  <Button key='ok' type='primary' danger onClick={onDeletePayment} loading={loader}>
                    Yes
                  </Button>,
                ]}
              >
                You are deleting the payment, once you delete the payment, the invoice details will
                be updated{' '}
              </Modal>
            </div>
          )}
        </Card>

        <div>
          <Modal
            open={visible && !isCancelled}
            title={'Edit Payment'}
            onCancel={onCancel}
            onOk={handleSave}
            destroyOnClose
            footer={[
              <Button key='back' onClick={onCancel}>
                Cancel
              </Button>,
              <Button key='submit' type='primary' loading={loader} onClick={handleSave}>
                Submit
              </Button>,
            ]}
          >
            <Form form={editForm} name='orderInventoryForm' layout='vertical' onFinish={handleSave}>
              <div className='d-flex gap-3'>
                <Form.Item
                  label='Amount'
                  name='amount'
                  className='payment-form-child'
                  rules={[
                    { required: true, message: 'Please enter the amount' },
                    { validator: handleNegativeValidator },
                  ]}
                >
                  <InputNumber
                    className='w-100'
                    precision={2}
                    type='number'
                    placeholder='Enter amount'
                    addonBefore='$'
                  />
                </Form.Item>

                <Form.Item
                  label='Payment Method'
                  name='paymentMethod'
                  className='payment-form-child'
                  rules={[{ required: true, message: 'Please select a payment method' }]}
                >
                  <Select
                    placeholder='Select payment method'
                    loading={loader}
                    onChange={(value) => {
                      setEditIsCreditRevert(value);
                    }}
                  >
                    <Select.Option value='PayPal'>PayPal</Select.Option>
                    <Select.Option value='E-Transfer'>E-Transfer</Select.Option>
                    <Select.Option value='CreditCard'>Credit Card</Select.Option>
                    <Select.Option value='CreditRevert'>Credit Revert</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className='d-flex gap-3'>
                <Form.Item
                  label='Payment Type'
                  name='paymentType'
                  className='payment-form-child'
                  rules={[
                    {
                      required: isEditCreditRevert !== 'CreditRevert',
                      message: 'Please select a Payment Type',
                    },
                  ]}
                >
                  <Select
                    placeholder='Select Payment Type'
                    loading={loader}
                    disabled={isEditCreditRevert === 'CreditRevert'}
                  >
                    <Select.Option value='Deposit'>Deposit</Select.Option>
                    <Select.Option value='Amount'>Amount</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  className='payment-form-child'
                  name='dateOfPayment'
                  rules={[{ required: true, message: 'Please select Date of Payment' }]}
                  label='Date of Payment'
                >
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      allowClear={false}
                      onChange={setDate}
                      disabledDate={disabledDate}
                      defaultValue={dayjs(recordDate, 'MM/DD/YYYY')}
                      className='w-100'
                      format={'MM/DD/YYYY'}
                      placeholder='Select Date of Payment'
                      showTime={false}
                      style={{ backgroundColor: 'white', color: 'black' }}
                    />
                  </ConfigProvider>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>
        <Form form={paymentForm} component={false}>
          <Table
            loading={loading || loader}
            pagination={false}
            columns={mergedColumns}
            rowClassName='editable-row'
            rowKey='id'
            dataSource={payments}
            className='pt-3'
          />
        </Form>
      </div>
    </div>
  );
}

export default OrderDetailsPayment;
