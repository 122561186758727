import React, { useEffect, useState } from 'react';
import { Button, Table, message } from 'antd';
import { UserOrders } from '../../schema/order';
import { getAllOrdersForProduct, replaceSomeOrders } from '../../config/api-routes';
import api from '../../config/axios';
import { useNavigate } from 'react-router-dom';

function ReplaceSomeCustomerOrderTable({
  recordId,
  closeModal,
  selectedOrderFormId,
  userRecord,
  selectedProduct,
}: any) {
  const [dataSource, setDataSource] = useState<UserOrders[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<UserOrders[]>([]);
  const [quantityTotal, setQuantityTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (Number(recordId) > 0) {
      fetchOrdersById();
    }
  }, [recordId]);

  const fetchOrdersById = async () => {
    setLoading(true);
    api
      .get<never, UserOrders[]>(`${getAllOrdersForProduct}/${Number(recordId)}`)
      .then((result) => {
        setDataSource(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleReplaceSome = () => {
    setLoading(true);

    const orderIds = selectedRows.map((item) => item.orderId);

    const payload = {
      changedId: recordId,
      replaceId: selectedProduct[0].id,
      orders: orderIds,
    };

    api
      .put(`${replaceSomeOrders}`, payload)
      .then((res: any) => {
        setLoading(false);
        message.success(res?.message);
        navigate('/order-inventory');
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.message);
      });
  };

  const columns = [
    {
      title: ' Quantity ',
      dataIndex: 'quantity',
    },
    {
      title: 'Ordered Quantity',
      dataIndex: 'orderedQuantity',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
    },
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
    },
    {
      title: 'Applied Tier',
      dataIndex: 'appliedTier',
    },
    {
      title: 'Price per piece',
      dataIndex: 'pricePerPiece',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserOrders[]) => {
      setSelectedRows(selectedRows);
      const selectedQuantityTotal = selectedRows.reduce((acc, item) => acc + item.quantity, 0);
      setQuantityTotal(selectedQuantityTotal);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.id === recordId, // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className='px-2 pt-2'>
      <div>
        <div className='d-flex align-items-center pb-2' style={{ justifyContent: 'space-between' }}>
          <div className='d-flex gap-4'>
            <div className='d-flex gap-2'>
              <b className='secondary-color'>Selected Quantity:</b> <span>{quantityTotal}</span>
            </div>
          </div>
          <div className='align-self-center'>
            <Button
              onClick={handleReplaceSome}
              disabled={!selectedRows.length || !selectedProduct.length}
              className='d-flex table-title-row'
              type='primary'
            >
              Replace
            </Button>
          </div>
        </div>
      </div>

      <Table
        scroll={{ y: '55vh' }}
        className='pt-2'
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        loading={loading}
        rowClassName={() => 'editable-row'}
        rowKey='id'
        bordered
        pagination={false}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
}

export default ReplaceSomeCustomerOrderTable;
