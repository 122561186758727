import React from 'react';
import { InvoiceDetails, Product, ShippingDetails } from '../../../schema/order';

type InvoiceProps = {
  productDetails: Product[];
  invoiceDetails: InvoiceDetails;
  shippingDetails: ShippingDetails;
  orderNumber: string;
  depositDates: [];
  depositAmounts: [];
  paymentDates: [];
  paymentAmounts: [];
  getOrderString: () => string;
};
const Invoice = ({
  productDetails,
  invoiceDetails,
  shippingDetails,
  orderNumber,
  depositDates,
  depositAmounts,
  paymentDates,
  paymentAmounts,
  getOrderString,
}: InvoiceProps) => {
  return (
    <body>
      <div id='header'>
        <div id='titlewrapper'>
          <div id='title'>
            {' '}
            Unicorn Blooms <br /> {getOrderString()} Invoice <br />{' '}
          </div>
          <div id='datetitle'></div>
        </div>
      </div>
      <table className='width-50'>
        <tbody>
          <tr>
            <th>Payable to :</th>
          </tr>
          <tr>
            <td>Unicorn Blooms Inc.</td>
          </tr>
          <tr>
            <td>100 King St East, Omemee, ON, K0L2W0</td>
          </tr>
          <tr>
            <td>e-transfer to: contact@unicornblooms.com</td>
          </tr>
          <tr>
            <td>Email us for Paypal (2.9% fee)</td>
          </tr>
        </tbody>
      </table>
      <br />

      <div>
        <div>
          <table>
            <tr>
              <th colSpan={6}>Order Number : {orderNumber}</th>
            </tr>
            <tr>
              <th colSpan={6}>Invoice for : {shippingDetails.shippingAddressBusiness}</th>
            </tr>
            <tr>
              <td colSpan={3}>Name</td>
              <td colSpan={3}>{shippingDetails.shippingAddressName}</td>
            </tr>
            <tr>
              <td colSpan={3}>Email</td>
              <td colSpan={3}>{shippingDetails.shippingEmail}</td>
            </tr>
            <tr>
              <td colSpan={3}>Phone</td>
              <td colSpan={3}>+1 {shippingDetails.shippingAddressPhone}</td>
            </tr>
            <tr>
              <td colSpan={3}>Address</td>
              <td colSpan={3}>
                {shippingDetails.shippingAddressStreetAddress},{' '}
                {shippingDetails.shippingAddressAdddressLine2},{' '}
                {shippingDetails.shippingAddressCity}, {shippingDetails.shippingAddressPostalCode}
              </td>
            </tr>
            <tr>
              <td colSpan={3}>Province</td>
              <td colSpan={3}>{shippingDetails.shippingAddressProvince}</td>
            </tr>
          </table>
        </div>
        <br></br>
        <div>
          <table>
            <thead>
              <tr>
                <th>Variety</th>
                <th>Type</th>
                <th>Shipment</th>
                <th>Size</th>
                <th>Price Per Piece</th>
                <th>Quantity</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {productDetails.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.shipment?.toUpperCase()}</td>
                  <td>{item.size}</td>
                  <td className='align-right'>${item.pricePerPiece?.toFixed(2)}</td>
                  <td>{item.quantity}</td>
                  <td className='align-right'>${item.totalPrice?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className='align-left' colSpan={5}>
                  Subtotal:
                </td>
                <td className='align-right' colSpan={1}>
                  ${invoiceDetails.subTotal.toFixed(2)}
                </td>
              </tr>
              {invoiceDetails.discount > 0 && (
                <tr>
                  <td className='align-left' colSpan={5}>
                    Discount ({invoiceDetails.discount}%) :{' '}
                  </td>
                  <td className='align-right' colSpan={1}>
                    ${invoiceDetails.discountAmount.toFixed(2)}
                  </td>
                </tr>
              )}

              {invoiceDetails.creditAmount > 0 && (
                <tr>
                  <td className='align-left' colSpan={5}>
                    Credits :{' '}
                  </td>
                  <td className='align-right' colSpan={1}>
                    ${invoiceDetails.creditAmount.toFixed(2)}
                  </td>
                </tr>
              )}

              {invoiceDetails.specialDiscountAmount > 0 && (
                <tr>
                  <td className='align-left' colSpan={5}>
                    Special Discount :{' '}
                  </td>
                  <td className='align-right' colSpan={1}>
                    ${invoiceDetails.specialDiscountAmount.toFixed(2)}
                  </td>
                </tr>
              )}

              <tr>
                <td className='align-left' colSpan={5}>
                  Shipping:
                </td>
                <td className='align-right' colSpan={1}>
                  ${invoiceDetails.shippingAmount.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className='align-left' colSpan={5}>
                  {(invoiceDetails.tax.gst.rate != 0 && (invoiceDetails.tax.gst.taxType + ' (' + invoiceDetails.tax.gst.rate + '%):')) ||
                    (invoiceDetails.tax.hst.rate != 0 && (invoiceDetails.tax.hst.taxType + ' (' + invoiceDetails.tax.hst.rate + '%):'))}
                </td>
                <td className='align-right' colSpan={1}>
                  ${(invoiceDetails.tax.gst.rate != 0 && invoiceDetails.tax.gst.amount) || (invoiceDetails.tax.hst.rate != 0 && invoiceDetails.tax.hst.amount)}
                </td>
              </tr>
              {invoiceDetails.tax.pstqst.taxType && (<tr>
                <td className='align-left' colSpan={5}>
                  {'PST (' + invoiceDetails.tax.pstqst.rate + '%):'}
                </td>
                <td className='align-right' colSpan={1}>
                  ${(invoiceDetails.tax.pstqst.amount)}
                </td>
              </tr>)}
              <tr>
                <td className='align-left' colSpan={5}>
                  Grand Total:
                </td>
                <td className='align-right' colSpan={1}>
                  ${invoiceDetails.grandTotal.toFixed(2)}
                </td>
              </tr>

              {depositDates && depositDates.length > 0 && (
                <span>
                  <tr>
                    <td colSpan={7}>Deposit received:</td>
                  </tr>

                  {depositDates.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={3} style={{ textAlign: 'left' }}>
                          {item}
                        </td>
                        <td colSpan={4} style={{ textAlign: 'right' }}>
                          {depositAmounts[index]}
                        </td>
                      </tr>
                    );
                  })}
                </span>
              )}

              {paymentDates && paymentDates.length > 0 && (
                <span>
                  <tr>
                    <td colSpan={7}>Payment received:</td>
                  </tr>

                  {paymentDates.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={3} style={{ textAlign: 'left' }}>
                          {item}
                        </td>
                        <td colSpan={4} style={{ textAlign: 'right' }}>
                          {paymentAmounts[index]}
                        </td>
                      </tr>
                    );
                  })}
                </span>
              )}

              <tr>
                <td colSpan={3} style={{ textAlign: 'left' }}>
                  Total Paid:
                </td>
                <td colSpan={4} style={{ textAlign: 'right' }}>
                  {invoiceDetails.amountPaid?.toFixed(2)}
                </td>
              </tr>

              {invoiceDetails.status !== 'PAID' && invoiceDetails.status !== 'OVERPAID' && (
                <tr>
                  <td colSpan={3} style={{ textAlign: 'left' }}>
                    Remaining Amount:
                  </td>
                  <td colSpan={4} style={{ textAlign: 'right' }}>
                    {invoiceDetails.remainingAmount?.toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </body>
  );
};

export default Invoice;
