const weeksData = [];
for (let i = 0; i <= 52; i++) {
  weeksData.push({ id: i, value: `Week ${i}` });
}
const shipWeeks = JSON.stringify(weeksData, null, 2);
export const shipWeek = [shipWeeks];

export const PLPrintedStatus = [
  {
    value: 'Not Printed',
    id: 1,
  },
  {
    value: 'One Print',
    id: 2,
  },
  {
    value: 'Multiple Prints',
    id: 3,
  },
];

export const packedStatus = [
  {
    value: 'Not Packed',
    id: 0,
  },
  {
    value: 'Packed',
    id: 1,
  },
];

export const shipLabel = [
  {
    value: 'No value',
    id: 0,
  },
  {
    value: 'Has value',
    id: 1,
  },
];

export const shippedStatus = [
  {
    value: 'Not Shipped',
    id: 0,
  },
  {
    value: 'Shipped',
    id: 1,
  },
];

export const invoiceStatus = [
  {
    value: 'Not Invoiced',
    id: 0,
  },
  {
    value: 'Invoiced',
    id: 1,
  },
];

export const paymentStatus = [
  {
    value: 'Cancelled',
    id: 7,
  },
  {
    value: 'Not Paid',
    id: 1,
  },
  {
    value: 'Partial Payment',
    id: 2,
  },
  {
    value: 'Fully Paid',
    id: 3,
  },
  {
    value: 'Overpaid',
    id: 4,
  },
  {
    value: 'Deposit Paid',
    id: 5,
  },
  {
    value: 'Deposit Pending',
    id: 6,
  },
];
